<template>
  <div
    style="width: 90%; padding-top: 150px; margin: 0 auto;letter-spacing:0.5px;"

  >
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        text-align: center;
        line-height: 35px;
      "
    >
    <select class="action_menu"
        @change="onChange($event)"
        v-if="data.show"
        style='display:inline-block;text-align:center;font-family:"Calibri",sans-serif;font-weight:bold;max-width: 250px;'>
        <option disabled selected>sélectionnez un sous-traitant</option>
        <option v-for="(item) in data.subcontractors" :value="item.id" :key="item.id" :title="item.subcontractor.tradeName">{{ item.subcontractor.tradeName }}</option>
    </select>
    </p>
    <br />
    <br />
    <hr />
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        text-align: center;
        line-height: 35px;
      "
    >
      AVENANT RGPD <br />
      AU CONTRAT DU
      <text-field type="date" style="width:190px" v-model="referenceData.editableDate" v-if="data.show"/>
      <span v-if="!data.show"> {{ referenceData.editableDate | formatDate }} </span>
    </p>
    <br />
    <hr />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 25px;
        text-align: center;
        line-height: 35px;
      "
    >
      <span style="font-size: 15px; text-decoration: underline">ENTRE</span
      ><br />
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
      >{{ data.structureJuridiqueYellow }}</span
      ><br />
      (« Le donneur d’ordre responsable de traitement »)<br />
      <span style="font-size: 15px; text-decoration: underline">ET</span><br />
      <span >{{ referenceData.subTrade }}</span><span style="margin-left: 3px;">{{ referenceData.subJuridique }}</span
      ><br />
      (« Le prestataire sous-traitant »)<br /><br />

      <span style="font-size: 15px"
        >Le
        <span
          style="
            font-family: 'Calibri', sans-serif;
            font-size: 15px;
            line-height: 20px;
          "
        >{{ data.dateRouge }}</span
      ></span>
      <br />
      <br />
      <br />
      <br />
      <br />
    </p>

    <p
      style="
        margin-top: 200px;
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        text-align: center;
        line-height: 35px;
      "
    >
      SOMMAIRE
    </p>
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        text-align: justify;
        line-height: 35px;
      "
    >
      Préambule
    </p>
    <ol type="I" style="list-style-type: upper-roman">
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">Objet</li>
      <br />
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Description du traitement faisant l’objet de la sous-traitance
      </li>
      <br />
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Durée du contrat
      </li>
      <br />
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Obligations de <span >{{ referenceData.subTrade }}</span>&emsp;vis-à-vis
        <span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span>
        <span
          style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        >{{ data.structureJuridiqueYellow }}</span>
        . <br /><br />
        &emsp;&emsp;Traitement des données <br /><br />
        &emsp;&emsp;Confidentialité des données <br /><br />
        &emsp;&emsp;Formation des personnes<br /><br />
        &emsp;&emsp;Prise en compte des principes de protection des données dès
        la conception <br /><br />
        &emsp;&emsp;Sous-traitance<br /><br />
        &emsp;&emsp;Droit d’information des personnes concernées<br /><br />
        &emsp;&emsp;Exercice des droits des personnes<br /><br />
        &emsp;&emsp;Notification des violations de données à caractère
        personnel.<br /><br />
        &emsp;&emsp;Assistance à
        <span
          style="margin-right: 3px;font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span>
        <span
        >{{ data.structureJuridiqueYellow }}</span>
        dans le cadre du respect de ses obligations règlementaires.<br /><br />
        &emsp;&emsp;Mesures de sécurité.<br /><br />
        &emsp;&emsp;Sort des données<br /><br />
        &emsp;&emsp;Délégué à la protection des données<br /><br />
        &emsp;&emsp;Registre des catégories de activités de traitement<br /><br />
        &emsp;&emsp;Documentation<br /><br />
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Obligations de
        <span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span>
        <span
          style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        >{{ data.structureJuridiqueYellow }}</span>
        vis-à-vis de <span >{{ referenceData.subTrade }}</span><span style="margin-left: 3px;"
        >{{referenceData.subJuridique}}</span>
      </li>
      <br />
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Autorité de contrôle, loi applicable et attribution de juridiction
      </li>
      <br />
    </ol>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 25px;
      "
    >
      Le présent avenant ( « L’Avenant ») en date du
      <span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.dateRouge }}</span>
      conclu entre : <br /><br /><br />

      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
      >{{ data.structureJuridiqueYellow }}</span
      >, dont le siège social est sis
      <span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.adressSiegeSocial }}</span
      >, immatriculée au Registre du Commerce et des Sociétés de 
      <text-field placeholder="Tribunal de commerce" style="width:190px" v-model="referenceData.tribunal" v-if="data.show"/>
      <span v-if="!data.show"> {{ referenceData.tribunal }} </span>

      sous le numéro

      <text-field placeholder="saisir un numéro RC" style="width:190px" v-model="rcNumber" v-if="data.show"/>
      <span v-if="!data.show"> {{ rcNumber }} </span>
      (« Le donneur d’ordre responsable de traitement »).<br /><br />
      Et<br /><br />
      <span >{{ referenceData.subTrade }}</span><span style="margin-left: 3px;">{{ referenceData.subJuridique }}</span>,
      dont le siège social est sis

      <text-field placeholder="adresse du siège" style="width:190px" v-model="siegeAdress" v-if="data.show"/>
      <span v-if="!data.show"> {{ siegeAdress }} </span>
      , immatriculée au Registre du Commerce et des Sociétés de
      <text-field placeholder="ville" style="width:100px" v-model="city" v-if="data.show"/>
      <span v-if="!data.show"> {{ city }} </span>
      sous le numéro


      <text-field placeholder="saisir un numéro RC" style="width:190px" v-model="rcNumberSub" v-if="data.show"/>
      <span v-if="!data.show"> {{ rcNumberSub }} </span>
      (« Le prestataire sous-traitant »), <br /><br /><br /><br />

      Préambule<br /><br />
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
      >{{ data.structureJuridiqueYellow }}</span>
      ET <span >{{ referenceData.subTrade }}</span> ont signé un contrat le
      <span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.dateRouge  }}</span>
      (le « Contrat Initial »), dans le cadre duquel
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
      >{{ data.structureJuridiqueYellow }}</span>
      partage des données personnelles avec la société
      <span >{{ referenceData.subTrade }}</span>. <br />
      Afin de se conformer au Règlement Général sur la Protection des Données
      (le « RGPD »), les parties souhaitent amender le Contrat Initial en y
      incluant les dispositions ci-dessous. <br />
      Les autres dispositions du Contrat Initial demeurent inchangées.
      <br /><br />
    </p>

    <p style="font-family: 'Calibri', sans-serif; font-size: 15px">
      &emsp;&emsp;&emsp;&emsp;I. Objet.<br />
      Les présentes clauses ont pour objet de définir les conditions dans
      lesquelles <span >{{ referenceData.subTrade }}</span> s’engage à effectuer pour le
      compte de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
      >{{ data.structureJuridiqueYellow }}</span>
      les opérations de traitement de données à caractère personnel définies
      ci-après.<br />
      Dans le cadre de leurs relations contractuelles, les parties s’engagent à
      respecter la réglementation en vigueur applicable au traitement de données
      à caractère personnel et, en particulier, le règlement (UE) 2016/679 du
      Parlement européen et du Conseil du 27 avril 2016 applicable à compter du
      25 mai 2018 (ci-après, « le règlement européen sur la protection des
      données »).<br /><br />
    </p>
    <p style="font-family: 'Calibri', sans-serif; font-size: 15px">
      &emsp;&emsp;&emsp;&emsp;II. Description du traitement faisant l’objet de
      la sous-traitance.<br />
      Les prestations assurées par <span >{{ referenceData.subTrade }}</span> pour le
      compte de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
      >{{ data.structureJuridiqueYellow }}</span>
      sont explicitées dans le contrat signé par les parties le
      <span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.dateRouge }}</span>
      portant sur la fourniture des services IT (le « Contrat Initial ») et
      restent inchangées. <br />
      <br />
    </p>
    <p style="font-family: 'Calibri', sans-serif; font-size: 15px">
      &emsp;&emsp;&emsp;&emsp;III. Durée du contrat.<br />
      Le présent avenant entre en vigueur à compter du 25 mai 2018 et à vocation
      à former avec le « Contrat Initial » le « contrat » dont les conditions de
      durée et de validité demeurent inchangées<br /><br />
      <br />
    </p>
    <p style="font-family: 'Calibri', sans-serif; font-size: 15px">
      &emsp;&emsp;&emsp;&emsp;IV. Obligations de
      <span >{{ referenceData.subTrade }}</span> vis-à-vis de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
      >{{ data.structureJuridiqueYellow }}</span
      >.<br /><br />
      <span >{{ referenceData.subTrade }}</span> s'engage à :<br />
    </p>
    <ul style="list-style: '- '; margin-left: 10px">
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Traiter les données uniquement pour la ou les seule(s) finalité(s) qui
        fait/font l’objet de la sous-traitance
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Traiter les données conformément aux instructions documentées de
        <span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span
        >,
        <span
          style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        >{{ data.structureJuridiqueYellow }}</span>
        figurant au « contrat initial ». Si
        <span >{{ referenceData.subTrade }}</span> considère qu’une instruction constitue
        une violation du règlement européen sur la protection des données ou de
        toute autre disposition du droit de l’Union ou du droit des Etats
        membres relative à la protection des données, il en informe
        immédiatement
        <span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span
        >,
        <span
          style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        >{{ data.structureJuridiqueYellow }}</span
        >. En outre, si <span >{{ referenceData.subTrade }}</span> est tenu de procéder à
        un transfert de données vers un pays tiers ou à une organisation
        internationale, en vertu du droit de l’Union ou du droit de l’Etat
        membre auquel il est soumis, il doit informer
        <span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span
        >,
        <span
          style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        >{{ data.structureJuridiqueYellow }}</span>
        de cette obligation juridique avant le traitement, sauf si le droit
        concerné interdit une telle information pour des motifs importants
        d’intérêt public
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Garantir la confidentialité des données à caractère personnel traitées
        dans le cadre du présent contrat
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Veiller à ce que les personnes autorisées à traiter les données à
        caractère personnel en vertu du présent contrat :
        <ul style="list-style-type: circle">
          <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
            S’engagent à respecter la confidentialité ou soient soumises à une
            obligation légale appropriée de confidentialité
          </li>
          <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
            Reçoivent la formation nécessaire en matière de protection des
            données à caractère personnel
          </li>
        </ul>
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Prendre en compte, s’agissant de ses outils, produits, applications ou
        services, les principes de protection des données dès la conception et
        de protection des données par défaut
      </li>
    </ul>
    <br />
    <p>
      <span style="font-family: 'Calibri', sans-serif; font-weight: bold"
        >Sous-traitance</span
      ><br /><br />
    </p>

    <p style="font-family: 'Calibri', sans-serif; font-size: 15px">
      <span >{{ referenceData.subTrade }}</span> peut faire appel à un autre sous-traitant
      (ci-après, « sous-traitant ultérieur ») pour mener des activités de
      traitement spécifiques. Dans ce cas, il informe préalablement et par écrit
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
      >{{ data.structureJuridiqueYellow }}</span>
      de tout changement envisagé concernant l’ajout ou le remplacement de
      autres sous-traitants. Cette information doit indiquer clairement les
      activités de traitement sous-traitées, l’identité et les coordonnées du
      sous-traitant et les dates du contrat de sous-traitance.
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="font-family: 'Calibri', sans-serif"
      ></span>
      dispose d’un délai maximum de 15 jours à compter de la date de réception
      de cette information pour présenter ses objections. Cette sous-traitance
      ne peut être effectuée que si
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span>
      n’a pas émis d’objection pendant le délai convenu.<br /><br />
      Le sous-traitant ultérieur est tenu de respecter les obligations du
      présent contrat pour le compte et selon les instructions de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span
      >. Il appartient à <span >{{ referenceData.subTrade }}</span> de s’assurer que le
      sous-traitant ultérieur présente les mêmes garanties suffisantes quant à
      la mise en œuvre de mesures techniques et organisationnelles appropriées,
      de manière à ce que le traitement réponde aux exigences du règlement
      européen sur la protection des données. Si le sous-traitant ultérieur ne
      remplit pas ses obligations en matière de protection des données,
      <span >{{ referenceData.subTrade }}</span> demeure pleinement responsable devant
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span>
      de l’exécution par l’autre sous-traitant de ses obligations.<br /><br />
    </p>
    <span style="font-family: 'Calibri', sans-serif; font-weight: bold"
      >Droit d’information des personnes concernées</span
    ><br /><br />
    <p style="font-family: 'Calibri', sans-serif; font-size: 15px">
      Il appartient à
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span>
      de fournir l’information aux personnes concernées par les opérations de
      traitement au moment de la collecte des données.<br /><br />
    </p>
    <span style="font-family: 'Calibri', sans-serif; font-weight: bold"
      >Exercice des droits des personnes</span
    ><br /><br />
    <p style="font-family: 'Calibri', sans-serif; font-size: 15px">
      Dans la mesure du possible, <span >{{ referenceData.subTrade }}</span> doit aider
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span>
      à s’acquitter de son obligation de donner suite aux demandes d’exercice
      des droits des personnes concernées : droit de accès, de rectification,
      d’effacement et d’opposition, droit à la limitation du traitement, droit à
      la portabilité des données, droit de ne pas faire l’objet d’une décision
      individuelle automatisée (y compris le profilage).<br /><br />
      Lorsque les personnes concernées exercent auprès de
      <span >{{ referenceData.subTrade }}</span> des demandes d’exercice de leurs droits,
      <span >{{ referenceData.subTrade }}</span> doit adresser ces demandes dès réception
      par courrier électronique à
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span
      >.<br /><br />
    </p>
    <span style="font-family: 'Calibri', sans-serif; font-weight: bold"
      >Notification des violations de données à caractère personnel.</span
    ><br /><br />
    <p style="font-family: 'Calibri', sans-serif; font-size: 15px">
      <span >{{ referenceData.subTrade }}</span> notifie à la
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span>
      toute violation de données à caractère personnel dans un délai maximum de
      48 heures après en avoir pris connaissance et par le moyen le plus rapide
      et le plus sûr. Cette notification est accompagnée de toute documentation
      utile afin de permettre au
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span
      >, si nécessaire, de notifier cette violation à l’autorité de contrôle
      compétente. <br /><br />
      La notification contient au moins :<br /><br />
    </p>
    <ul style="list-style: '- '; margin-left: 10px">
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        La description de la nature de la violation de données à caractère
        personnel y compris, si possible, les catégories et le nombre
        approximatif de personnes concernées par la violation et les catégories
        et le nombre approximatif de enregistrements de données à caractère
        personnel concernés ;
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Le nom et les coordonnées du délégué à la protection des données ou d’un
        autre point de contact auprès duquel des informations supplémentaires
        peuvent être obtenues ;
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        La description des conséquences probables de la violation de données à
        caractère personnel ;
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        La description des mesures prises ou que
        <span >{{ referenceData.subTrade }}</span> propose de prendre pour remédier à la
        violation de données à caractère personnel, y compris, le cas échéant,
        les mesures pour en atténuer les éventuelles conséquences négatives.
      </li>
    </ul>
    <p style="font-family: 'Calibri', sans-serif; font-size: 15px">
      Si, et dans la mesure où il n’est pas possible de fournir toutes ces
      informations en même temps, les informations peuvent être communiquées de
      manière échelonnée sans retard indu.
      <br /><br />
      <span style="font-weight: 600"
        >Aide de <span >{{ referenceData.subTrade }}</span> dans le cadre du respect par
        <span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span
        >,
        <span
          style="margin-left: 3px;font-family: 'Calibri', sans-serif"
          
        >{{ data.structureJuridiqueYellow }}</span>
        de ses obligations règlementaires.</span
      ><br /><br />
      Dans la mesure de son implication dans le traitement,
      <span >{{ referenceData.subTrade }}</span> aide
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span>
      pour la réalisation de analyses d’impact relative à la protection des
      données.<br /><br />
      Dans la mesure de son implication dans le traitement,
      <span >{{ referenceData.subTrade }}</span> aide
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span>
      pour la réalisation de la consultation préalable de l’autorité de
      contrôle.<br /><br />
      <span style="font-weight: 600">Mesures de sécurité.</span><br /><br />
      <span >{{ referenceData.subTrade }}</span> s’engage à mettre en œuvre les mesures de
      sécurité validées dans l’analyse d’impact relative à la protection des
      données, les codes de conduites ou le cas échéant, règles prévues par le
      processus de prise en compte de la protection des données dès la
      conception et décrites dans son « Plan de Sécurisation des données ».<br /><br />
      <span style="font-weight: 600">Sort des données</span><br /><br />
      Au terme de la prestation de services relatifs au traitement de ces
      données, <span >{{ referenceData.subTrade }}</span> s’engage à renvoyer toutes les
      données à caractère personnel à
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span
      >. <br /><br />
      Le renvoi doit s’accompagner de la destruction de toutes les copies
      existantes dans les systèmes d’information de
      <span >{{ referenceData.subTrade }}</span>. Une fois détruites,
      <span >{{ referenceData.subTrade }}</span> devra justifier par écrit de la
      destruction.<br /><br />
      <span style="font-weight: 600">Délégué à la protection des données</span
      ><br /><br />
      Conformément à l’article 37 du règlement européen sur la protection des
      données, <span >{{ referenceData.subTrade }}</span> a nommé un délégué à la
      protection des données en la personne de 

      <text-field placeholder="saisir le prénom"  style="width:190px" v-model="referenceData.prenomDelegue" v-if="data.show"/>
      <span v-if="!data.show"> {{ referenceData.prenomDelegue }} </span>
      <text-field placeholder="saisir le nom"  style="width:190px;margin-left: 4px;" v-model="referenceData.nomDelegue" v-if="data.show"/>
      <span v-if="!data.show"> {{ referenceData.nomDelegue }} </span>
      .<br /><br />

      <span style="font-family: 'Calibri', sans-serif; font-weight: 600"
        >Registre des catégories de activités de traitement</span
      ><br /><br />
      <span >{{ referenceData.subTrade }}</span> déclare tenir par écrit un registre de
      toutes les catégories de activités de traitement effectuées pour le compte
      de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span>
      comprenant :<br />
    </p>

    <ul style="list-style: '- '; margin-left: 10px">
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Le nom et les coordonnées de
        <span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span
        >,
        <span
          style="margin-left: 3px;font-family: 'Calibri', sans-serif"
          
        >{{ data.structureJuridiqueYellow }}</span>
        pour le compte duquel il agit, des éventuels sous-traitants et, le cas
        échéant, du délégué à la protection des données ;
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Les catégories de traitements effectués pour le compte de
        <span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span
        >,
        <span
          style="margin-left: 3px;font-family: 'Calibri', sans-serif"
          
        >{{ data.structureJuridiqueYellow }}</span>
        ;
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Le cas échéant, les transferts de données à caractère personnel vers un
        pays tiers ou à une organisation internationale, y compris
        l'identification de ce pays tiers ou de cette organisation
        internationale et, dans le cas des transferts visés à l'article 49,
        paragraphe 1, deuxième alinéa du règlement européen sur la protection
        des données, les documents attestant de l'existence de garanties
        appropriées ;
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Dans la mesure du possible, une description générale des mesures de
        sécurité techniques et organisationnelles, y compris entre autres, selon
        les besoins :
        <ul style="list-style-type: circle">
          <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
            La pseudonymisation et le chiffrement des données à caractère
            personnel ;
          </li>
          <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
            Des moyens permettant de garantir la confidentialité, l'intégrité,
            la disponibilité et la résilience constantes des systèmes et des
            services de traitement ;
          </li>
          <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
            Des moyens permettant de rétablir la disponibilité des données à
            caractère personnel et l'accès à celles-ci dans des délais
            appropriés en cas d’incident physique ou technique ;
          </li>
          <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
            Une procédure visant à tester, à analyser et à évaluer régulièrement
            l'efficacité des mesures techniques et organisationnelles pour
            assurer la sécurité du traitement.
          </li>
        </ul>
      </li>
    </ul>
    <br />
    <br />
    <span style="font-weight: 600">Documentation</span><br /><br />
    <p style="font-family: 'Calibri', sans-serif; font-size: 15px">
      <span >{{ referenceData.subTrade }}</span> met à la disposition de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span>
      la documentation nécessaire pour démontrer le respect de toutes ses
      obligations et pour permettre la réalisation de audits, y compris des
      inspections, par
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span>
      ou un autre auditeur qu'il a mandaté, et contribuer à ces audits.<br /><br />
      &emsp;&emsp;&emsp;&emsp;V. Obligations de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span>
      vis-à-vis de <span >{{ referenceData.subTrade }}</span><br /><br />
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
        
      >{{ data.structureJuridiqueYellow }}</span>
      s’engage à :<br />
    </p>
    <ul style="list-style: '- '; margin-left: 10px">
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Fournir à <span >{{ referenceData.subTrade }}</span> les données visées au II des
        présentes clauses
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Documenter par écrit toute instruction concernant le traitement des
        données par <span >{{ referenceData.subTrade }}</span>
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Veiller, au préalable et pendant toute la durée du traitement, au
        respect des obligations prévues par le règlement européen sur la
        protection des données de la part de <span >{{ referenceData.subTrade }}</span>.
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Superviser le traitement, y compris réaliser les audits et les
        inspections auprès de <span >{{ referenceData.subTrade }}</span>.
      </li>
    </ul>

    <p style="font-family: 'Calibri', sans-serif; font-size: 15px">
      &emsp;&emsp;&emsp;&emsp;VI. Autorité de contrôle, loi applicable et
      attribution de juridiction<br /><br />
      Les parties ont choisi la CNIL comme autorité de contrôle de leur
      conformité RGPD.<br />
      Le Contrat est soumis au droit français <br />
      Tout différend relatif au Contrat sera soumis à la compétence exclusive du
      Tribunal de commerce de 
      <text-field placeholder="Tribunal de commerce"  style="width:190px" v-model="referenceData.tribunal" v-if="data.show"/>
      <span v-if="!data.show"> {{ referenceData.tribunal }} </span>
      .<br /><br /><br />
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    </p>
    <p></p>
    <div style="position: relative">
      <div style="position: absolute; left: 40px; bottom: 30px">
        <span style="font-family: 'Calibri', sans-serif">
        <text-field placeholder="saisir la ville"  style="width:190px" v-model="referenceData.cityReference" v-if="data.show"/>
        <span v-if="!data.show"> {{ referenceData.cityReference }} </span>
          , le
          <span
            style="
              font-family: 'Calibri', sans-serif;
              font-size: 15px;
              line-height: 20px;
            "
          >{{data.dateRouge}}</span>
        </span>
        <br /><br />
        <span style="font-family: 'Calibri', sans-serif; font-size: 13px"
          >Pour <span >{{ referenceData.subTrade }}</span></span
        ><br /><br />
        <div style="height: 120px; width: 180px; border: 1px solid black"></div>
        <br /><br />
        <text-field placeholder="civilité, prénom, nom"  style="width:190px" v-model="referenceData.civiliteNomPrenomSub" v-if="data.show"/>
        <span v-if="!data.show"> {{ referenceData.civiliteNomPrenomSub }} </span>
      </div>
      <div style="position: absolute; right: 20px; bottom: 30px">
        <span style="font-family: 'Calibri', sans-serif; font-size: 13px"></span
        ><br /><br />
        <span style="font-family: 'Calibri', sans-serif; font-size: 13px"
          >Pour
          <span
            style="font-family: 'Calibri', sans-serif"
          >{{ data.socialClient }}</span
          >,
          <span
            style="margin-left: 3px;font-family: 'Calibri', sans-serif"
          >{{ data.structureJuridiqueYellow }}</span></span
        ><br /><br />
        <div style="height: 120px; width: 180px; border: 1px solid black"></div>
        <br /><br />
        <text-field placeholder="civilité, prénom, nom" style="width:190px" v-model="referenceData.civiliteNomPrenom" v-if="data.show"/>
        <span v-if="!data.show"> {{ referenceData.civiliteNomPrenom }} </span>

      </div>
    </div>
    <br /><br />
  </div>
</template>

<script>
import textField from './components/textField.vue';
export default {
  components: { textField },
  name: "avenantRgpdResponsableDeTraitement",
  props:{
    data:{
      type:Object,
      default:()=>{}
    },
    oldReference:{
        type:Object,
        default:()=>{}
    },
  },
  data(){
    return{
      referenceData:{
        subTrade:'',
        subJuridique:'',
        editableDate :'',
        tribunal:'',
        cityReference:'',
        civiliteNomPrenom:'',
        civiliteNomPrenomSub:'',
        civilite:'',
        nomDelegue :'',
        prenomDelegue:''
      }
    }
  },
  mounted() {
    if(!!this.oldReference && Object.keys(this.oldReference))
      this.referenceData = this.oldReference
  },
  methods:{
    onChange(e){
      const item = this.data.subcontractors.filter(el=>el.id == e.target.value)[0];
      console.log("🚀 ~ file: avenantRgpdResponsableDeTraitement.vue:905 ~ onChange ~ item:", item)
      this.referenceData.subTrade = item.subcontractor.tradeName;
      this.referenceData.subJuridique = item.subcontractor.legalStatus
    }
  },
  watch:{
    referenceData:{
      handler(value){
        this.$store.dispatch('handleReferenceData',value)
      },
      deep:true
    },
  }
};
</script>

<style></style>
