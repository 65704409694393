<template>
  <div class="tw-mb-5 tw-text-black-100">
    <template v-if="data.length > 0">
      <div id="collaborateurs">
        <div class="tw-pt-10 tw-pb-3 tw-align-start"
          id="mes-partages-header">
          <EntrepriseHeaderBlockTextComponent 
            title="Mon registre de traitement" 
            description="Gérez votre registre de traitement en créant directement sur votre plateforme les traitements par activité ou bien en téléchargeant ceux que vous avez déjà créés." 
          />

          <div class="tw-mt-8 tw-flex tw-gap-x-6">
            <v-btn width="278px" elevation="0" @click="goToAdd">
              <svg width="13" height="26" viewBox="0 0 13 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.004 13.7702L4.87558 19.8987L3.34375 18.3668L8.70625 13.0043L3.34375 7.64182L4.87558 6.10999L11.004 12.2384C11.2071 12.4416 11.3212 12.7171 11.3212 13.0043C11.3212 13.2916 11.2071 13.5671 11.004 13.7702Z" fill="white"/>
              </svg>
              <span class="tw-pl-4 tw-text-white">Lancer le registre de traitement</span>
            </v-btn>
            <v-btn width="300px" elevation="0" color="#BB6BD9" @click="canShowImportActivityPopup = true">
              <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8 5.64687C16.9281 5.33638 17 4.99687 17 4.64286C17 3.10491 15.6563 1.85714 14 1.85714C13.3844 1.85714 12.8094 2.03125 12.3344 2.32723C11.4688 0.934375 9.85313 0 8 0C5.2375 0 3 2.07768 3 4.64286C3 4.72121 3.00313 4.79955 3.00625 4.8779C1.25625 5.44955 0 6.99911 0 8.82143C0 11.1283 2.01562 13 4.5 13H16C18.2094 13 20 11.3373 20 9.28571C20 7.48951 18.625 5.98929 16.8 5.64687ZM12.6469 8.22076L9.35313 11.2792C9.15938 11.4592 8.84063 11.4592 8.64688 11.2792L5.35313 8.22076C5.0375 7.92768 5.2625 7.42857 5.70625 7.42857H7.75V4.17857C7.75 3.92321 7.975 3.71429 8.25 3.71429H9.75C10.025 3.71429 10.25 3.92321 10.25 4.17857V7.42857H12.2938C12.7375 7.42857 12.9625 7.92768 12.6469 8.22076Z" fill="white"/>
              </svg>
              <span class="tw-pl-4 tw-text-white">Importer votre registre de traitement</span>
            </v-btn>
          </div>
        </div>
        <div class="tw-border-2 tw-p-6 tw-rounded-lg border-primary tw-mt-14">
          <SignupSecondaryTextComponent class="subheader tw-mb-6">
            La liste des registres de traitements
          </SignupSecondaryTextComponent>
          <div id="registers-table">
            <content-placeholders v-if="loading_table">
              <content-placeholders-text :lines="10" />
            </content-placeholders>
            <!-- table  -->
            <v-simple-table v-else height="600px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>
                      <div @click="addToOrder('function')" class="tw-flex tw-justify-start tw-cursor-pointer">
                        <span>Fonction</span>
                          <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='function' && orderType=='desc'" alt="">
                          <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='function' && orderType=='asc'" alt="">
                          <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                      </div>
                    </th>
                    <th>
                      <div @click="addToOrder('registerActivityName')" class="tw-cursor-pointer tw-flex tw-justify-start tw-items-center">
                        <span>Activité</span>
                        <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='registerActivityName' && orderType=='desc'" alt="">
                          <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='registerActivityName' && orderType=='asc'" alt="">
                          <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                      </div>
                    </th>
                    <th>
                        <div @click="addToOrder('email')" class="tw-cursor-pointer tw-flex tw-justify-start tw-items-center">
                          <span>Email</span>
                          <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='email' && orderType=='desc'" alt="">
                          <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='email' && orderType=='asc'" alt="">
                          <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                        </div>
                    </th>
                    <th>
                      <div @click="addToOrder('status')" class="tw-cursor-pointer">
                        <span>Statut</span>
                      </div>
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="length > 0">
                  <tr v-for="item in data" :key="item.name" class="tw-cursor-pointer">
                    <td :title="item.function" class="limit-length">{{ item.function }}</td>
                    <td :title="item.registerActivityName" class="limit-length">{{ item.registerActivityName }}</td>
                    <td 
                      :title="item.collaborateur ? item.collaborateur.email : ''" 
                      class="limit-length"
                    >{{ item.collaborateur ? item.collaborateur.email : '' }}</td>
                    <td v-html="handleStatusSheet(item)"></td>
                    <td>
                      <div class="tw-cursor-pointer tw-flex tw-justify-center tw-items-center tooltip">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="11" cy="11" r="10.5" stroke="var(--cta-bg-color)"/>
                          <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5 12)" fill="var(--cta-bg-color)"/>
                          <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 10 12)" fill="var(--cta-bg-color)"/>
                          <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 15 12)" fill="var(--cta-bg-color)"/>
                        </svg>
                        <div class="tooltiptext">
                          <ul>
                            <li v-if="item.status === 1" @click="goToDetail(item)">Voir</li>
                            <li v-if="item.status === 2" @click="download(item)">Voir</li>
                            <li v-if="item.status !== 0" @click="ModifiyDetails(item)">Modifier</li>
                            <li @click="deleteRegistre(item)">Supprimer</li>
                            <li v-if="item.status === 0" @click="resendInvitation(item)">Renvoyer l'invitation</li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5" class="text-center">
                      Pas de données disponibles
                    </td>
                  </tr>
                </tbody>
                <tfoot class="text-center">
                  <tr v-if="length > 1">
                    <td colspan="12">
                      <footer-table-pagination-component :limit="perPage" :choosingPage="choosedPage" :length="length" :page="page" @changeLimitAndPage="changeLimitAndPage" @changePage="changePage" />
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
            <!-- end table  -->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <EntrepriseHeaderBlockTextComponent
        class="tw-mt-6"
        :title="`${user.workInCompany.tradeName}, mon registre de traitement : étape préalable`"
        description="Pour commencer, veuillez vérifier ces informations,  les modifier et si besoin les compléter. c’est rapide !" />

      <form-with-choices :entity="manager" type="manager" :errors="errors" :is-manager-delegate="form.isManagerDelegate"
        :is-manager-responsible="form.isManagerResponsible" :get-inputs="getInputs" show-multiple-choices
        label="Vous, vos informations en qualité de manager de la plateforme Viqtor®" :choicesObject="multipleChoises"
        @setChoice="setChoiceForMultipleCases" />

      <transition name="slide-fade">
        <form-with-choices v-if="!form.isManagerResponsible" :entity="form.responsible" type="responsible"
          :errors="errors" :is-responsable-designated="form.isResponsibleDelegate" :get-inputs="getInputs"
          :show-choices="!form.isManagerDelegate" label="Information du responsable de l’entreprise"
          choicesDescription="Est-ce que le responsable de votre entreprise a été désigné comme le DPO (Délégué à la Protection des Données) ou le CPD (Correspondant à la Protection des Données) ?"
          @setChoice="setChoiceForCompanyManager" />
      </transition>

      <transition name="slide-fade">
        <div
          v-if="!form.isManagerDelegate && !form.isResponsibleDelegate || (form.isManagerResponsible && !form.isManagerDelegate)">
          <form-with-choices :entity="form.delegate" type="delegate" :errors="errors" :is-responsable-designated="false"
            :get-inputs="getInputs" label="Informations du délégué à la protection des données" />
        </div>
      </transition>

      <div class="tw-mt-16 tw-flex tw-justify-end">
        <primary-button-component :loading="idSendingForm" :style="{
          backgroundColor: `${referer.theme.ctaBgColor} !important`,
          color: `${referer.theme.ctaTextColor} !important`
        }" type="submit" @click="submit">Valider pour
          poursuivre</primary-button-component>
      </div>
    </template>
    <ConfirmeDeletePopup v-if="selectedItem && openPopup" :openPopup="openPopup" :item="selectedItem"
      v-on:fetchData="pagination" @close="openPopup = false" />
    <ModificationPopup v-if="selectedItem && openPopupModification" :openPopup="openPopupModification" :item="selectedItem"
      v-on:fetchData="pagination" @close="openPopupModification = false" />
    <ImportActivityPopup 
      v-if="canShowImportActivityPopup" 
      :openPopup="canShowImportActivityPopup" 
      @close="canShowImportActivityPopup = false"
      @closeAndFetch="closeAndPaginate"
    />
  </div>
</template>

<script>
import PrimaryButtonComponent from '@/components/common/PrimaryButtonComponent'
import FormWithChoices from '../components/FormWithChoices'
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import { mapGetters } from 'vuex';
import { persistRegisterPriorStep, getResponsible, checkEcosystemExist } from "@/features/ProcessingRegister/services/appApi";
import { getNewCollaboratorsPaginate, resendCollaborator } from "@/features/company/services/appApi";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import ConfirmeDeletePopup from "@/features/ProcessingRegister/components/ConfirmeDeletePopup";
import ModificationPopup from "@/features/ProcessingRegister/components/ModificationPopup";
import ImportActivityPopup from "@/features/ProcessingRegister/components/ImportActivityPopup";
import FooterTablePaginationComponent from '@/components/common/FooterTablePaginationComponent.vue';
import ApiService from "@/services/api.service";

export default {
  name: "PreRegister",
  components: {
    ConfirmeDeletePopup,
    SignupSecondaryTextComponent,
    EntrepriseHeaderBlockTextComponent, FormWithChoices, PrimaryButtonComponent,
    FooterTablePaginationComponent, ModificationPopup, ImportActivityPopup
  },
  computed: {
    ...mapGetters({
      user: 'getAuthUser'
    }),
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      multipleChoises: [
        {
          type: "responsible",
          description: "Avez-vous été désigné comme responsable de l’entreprise ?",
          choise: true
        },
        {
          type: "delegate",
          description: "Avez-vous été désigné comme le DPO (Délégué à la Protection des Données) ou le CPD (Correspondant à la Protection des Données) ?",
          choise: true
        },
      ],
      showTooltip: false,
      manager: {
        firstName: "",
        lastName: "",
        fonction: "",
        address: "",
        postalCode: "",
        city: "",
        phone: "",
        mobilePhone: "",
        email: ""
      },
      ISharedDataForEntities: {
        firstName: "",
        lastName: "",
        fonction: "",
        address: "",
        postalCode: "",
        city: "",
        phone: "",
        mobilePhone: "",
        email: ""
      },
      form: {
        isManagerDelegate: true,
        isManagerResponsible: true,
        isResponsibleDelegate: false,
        responsible: {},
        delegate: {},
      },
      errors: {
        isManagerDelegate: null,
        isManagerResponsible: null,
        isResponsibleDelegate: null,
        responsible: null,
        manager: null,
        delegate: null,
      },
      idSendingForm: false,
      isWithEcosystem: false,
      ecosystemExist: false,
      loading_table: false,
      data: [""],
      page: 1,
      order: null,
      orderby: "",
      orderType: "",
      perPage: 10,
      choosedPage: 1,
      filter: {
        gouvernance: "Aucun",
        ecosysteme: "Aucun",
        elearning: "Aucun",
      },
      openPopup: false,
      openPopupModification: false,
      selectedItem: null,
      length: 1,
      canShowImportActivityPopup: false,
    }
  },
  async mounted() {
    this.form.responsible = { ...this.ISharedDataForEntities };
    this.form.delegate = { ...this.ISharedDataForEntities };
    await this.checkEcosystemExist();
    await this.pagination();
    await this.getCurrentResponsible();
    this.manager = {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      fonction: this.user.function,
      address: this.user.address,
      postalCode: this.user.zipCode,
      city: this.user.city,
      phone: this.user.phone,
      mobilePhone: this.user.mobilePhone,
      email: this.user.email
    };
  },
  methods: {
    resendInvitation(module) {
      resendCollaborator(module.type, module.id)
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        });
    },
    changeLimitAndPage(obj) {
      this.perPage = parseInt(obj.limit);
      this.page == obj.page?this.pagination():this.changePage(obj.page);
    },
    changePage(page){
      this.page=parseInt(page);
      setTimeout(()=>{
        document.querySelector('#collaborateurs-table').scrollIntoView({
          behavior: 'smooth'
        });
      },200)
    },
    checkEcosystemExist() {
      checkEcosystemExist()
        .then(response => {
          this.ecosystemExist = response.data.data.ecosystemExist;
        });
    },
    getCurrentResponsible() {
      getResponsible()
        .then(response => {
          if (response.data.data.responsible) {
            this.form.responsible.firstName = response.data.data.responsible.firstName;
            this.form.responsible.lastName = response.data.data.responsible.lastName;
            this.form.responsible.fonction = response.data.data.responsible.function;
            this.form.responsible.email = response.data.data.responsible.email;
            this.form.responsible.phone = response.data.data.responsible.phone;
            this.form.responsible.postalCode = response.data.data.responsible.zipCode;
            this.form.responsible.address = response.data.data.responsible.address;
            this.form.responsible.city = response.data.data.responsible.city;
            this.form.responsible.mobilePhone = response.data.data.responsible.mobilePhone;
          }
          if (response.data.data.manager) {
            this.manager.firstName = response.data.data.manager.firstName;
            this.manager.lastName = response.data.data.manager.lastName;
            this.manager.fonction = response.data.data.manager.function;
            this.manager.email = response.data.data.manager.email;
            this.manager.phone = response.data.data.manager.phone;
            this.manager.postalCode = response.data.data.manager.zipCode;
            this.manager.address = response.data.data.manager.address;
            this.manager.city = response.data.data.manager.city;
            this.manager.mobilePhone = response.data.data.manager.mobilePhone;
          }
          if (response.data.data.delegate) {
            this.form.delegate.firstName = response.data.data.delegate.firstName;
            this.form.delegate.lastName = response.data.data.delegate.lastName;
            this.form.delegate.fonction = response.data.data.delegate.function;
            this.form.delegate.email = response.data.data.delegate.email;
            this.form.delegate.phone = response.data.data.delegate.phone;
            this.form.delegate.postalCode = response.data.data.delegate.zipCode;
            this.form.delegate.address = response.data.data.delegate.address;
            this.form.delegate.city = response.data.data.delegate.city;
            this.form.delegate.mobilePhone = response.data.data.delegate.mobilePhone;
          }
          // this.showResponsible = true;
          this.form.isManagerDelegate = response.data.data.delegate ? response.data.data.isManagerDelegate : true;
          this.form.isManagerResponsible = response.data.data.responsible ? response.data.data.isManagerResponsible : true;
          this.form.isResponsibleDelegate = response.data.data.delegate ? response.data.data.isResponsibleDelegate : false;

          this.multipleChoises.forEach((item) => {
            if (item.type === 'delegate') {
              item.choise = this.form.isManagerDelegate;
            }
            if (item.type === 'responsible') {
              item.choise = this.form.isManagerResponsible;
            }
          });
        }).catch(() => {
        });
    },
    goToAdd() {
      this.$store.dispatch('setProcessingRegister', { ...this.form, ecosystemExist: this.ecosystemExist });
      this.$router.push({ name: 'Register.FirstStep.Page' });
    },
    getInputs(entity) {
      return entity
        ? [
          { index: 'lastName', value: entity.lastName, change: (value) => { entity.lastName = value }, label: "Nom", type: "text" },
          { index: 'firstName', value: entity.firstName, change: (value) => { entity.firstName = value }, label: "Prénom", type: "text" },
          { index: 'fonction', value: entity.fonction, change: (value) => { entity.fonction = value }, label: "Fonction", type: "text" },
          { index: 'address', value: entity.address, change: (value) => { entity.address = value }, label: "Adresse professionnelle, N° et rue", type: "text" },
          { index: 'postalCode', value: entity.postalCode, change: (value) => { entity.postalCode = value }, label: "Code postal", type: "number" },
          { index: 'city', value: entity.city, change: (value) => { entity.city = value }, label: "Ville", type: "text" },
          { index: 'phone', value: entity.phone, change: (value) => { entity.phone = value }, label: "Téléphone fixe entreprise", type: "tel" },
          { index: 'mobilePhone', value: entity.mobilePhone, change: (value) => { entity.mobilePhone = value }, label: "Téléphone mobile", type: "tel" },
          { index: 'email', value: entity.email, change: (value) => { entity.email = value }, label: "Adresse e-mail", type: "email" },
        ]
        : []
    },
    setChoiceForCompanyManager(value) {
      this.form.isResponsibleDelegate = value;
      if (value === true) {
        this.form.isManagerDelegate = false;
      }
    },
    setChoiceForMultipleCases(value) {
      this.multipleChoises.forEach((item) => {
        if (item.type === value.type) {
          item.choise = value.choise;
        }
        if (item.type === 'delegate') {
          this.form.isManagerDelegate = item.choise;
        }
        if (item.type === 'responsible') {
          this.form.isManagerResponsible = item.choise;
        }
        if (this.form.isManagerResponsible === false && this.form.isManagerDelegate === false) {
          this.form.isResponsibleDelegate = true;
        }
      });
    },
    setChoiceForVictorManager(value) {
      this.form.isManagerDelegate = value;
      if (value === true) {
        this.form.isResponsibleDelegate = false;
      }
    },
    resetValidation() {
      this.errors = {
        isManagerDelegate: null,
        isManagerResponsible: null,
        isResponsibleDelegate: null,
        responsible: null,
        manager: null,
        delegate: null,
      }
    },
    clearNotUsedData() {
      if (this.form.isManagerDelegate || this.form.isResponsibleDelegate)
        this.form.delegate = {
          firstName: "",
          lastName: "",
          fonction: "",
          address: "",
          postalCode: "",
          city: "",
          phone: "",
          mobilePhone: "",
          email: ""
        }

      if (this.form.isManagerResponsible)
        this.form.responsible = {
          firstName: "",
          lastName: "",
          fonction: "",
          address: "",
          postalCode: "",
          city: "",
          phone: "",
          mobilePhone: "",
          email: ""
        }

      if (this.form.isManagerResponsible && this.form.isManagerDelegate)
        this.form.isResponsibleDelegate = false
    },
    submit() {
      this.resetValidation();
      this.clearNotUsedData();
      this.filterData(this.form.responsible);
      this.filterData(this.form.delegate);
      this.idSendingForm = true;
      persistRegisterPriorStep(this.form)
        .then(response => {
          this.$store.dispatch('setProcessingRegister', { ...this.form, ecosystemExist: response.data.data.ecosystemExist });
          if (response.data.data.ecosystemExist) {
            this.$router.push({ name: 'Register.FirstStep.Page' });
          } else {
            this.$router.push({ name: 'Register.without-ecosystem.FirstStep.Page' });
          }
        })
        .catch(({ response }) => {
          console.log(response.data);
          if (response.status === 400) {
            if (typeof response.data.errors !== 'undefined') {
              this.errors = response.data.errors;
              const props = [
                'lastName',
                'firstName',
                'fonction',
                'address',
                'postalCode',
                'city',
                'phone',
                'mobilePhone',
                'email',
              ]
              const collaboratorTypes = ['responsible', 'manager', 'delegate']
              collaboratorTypes.forEach((type) => {
                if (Object.prototype.hasOwnProperty.call(this.errors, type)) {
                  props.forEach((prop) => {
                    if (Object.prototype.hasOwnProperty.call(this.errors[type], prop))
                      this.errors[type][prop] = this.getValidationMessage(prop, this.errors[type][prop])
                  })
                }
              })
            } else {
              this.$snackbar.showAlertMessage({
                message: response.data.message,
                type: "error",
              });
            }


          }
        })
        .finally(() => this.idSendingForm = false);
    },
    pagination(value = this.page, keyWord = '') {
      this.loading_table = true
      getNewCollaboratorsPaginate('register', value, keyWord,this.perPage, this.filter, this.orderby, this.orderType)
        .then((res) => {
          this.data = res.data.items;
          this.page = res.data.meta.currentPage;
          this.length = res.data.meta.totalPages;
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading_table = false;
        });
    },
    closeAndPaginate() {
      this.canShowImportActivityPopup = false;
      this.pagination();
    },
    addToOrder(item) {
      if (this.orderby === item && this.orderType === "desc") {
        this.orderby = null
        this.orderType = null
      } else {
        if (this.orderby == item) {
          this.orderType = "desc"
        }
        else {
          this.orderby = item
          this.orderType = "asc"
        }
      }
    },
    handleStatusSheet(item) {
      switch(item.status) {
        case 1:
          return `<div class="tw-flex tw-justify-center tw-items-center">
                    <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #27AE60;">
                      Actif
                    </div>
                  </div>`;
        case 2:
          return `<div class="tw-flex tw-justify-center tw-items-center">
                    <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #BB6BD9;">
                      Importé
                    </div>
                  </div>`;
        case 0: default:
          return `<div class="tw-flex tw-justify-center tw-items-center">
                    <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #E8973E;">
                      En cours
                    </div>
                  </div>`;
      }
    },
    goToDetail(item) {
      this.$router.push({ name: 'Register.PreRegister.Detail', params: { id: item.id } })
    },
    filterData(object) {

      for (var key in object) {
        if (key === 'phone' && (object[key] === '' || object[key] === null || object[key] === undefined)) {
          delete object[key];
        }
      }

    },
    deleteRegistre(item) {
      this.selectedItem = item;
      this.openPopup = true;
      this.openPopupModification = false;
    },
    ModifiyDetails(item) {
      this.selectedItem = item;
      this.openPopupModification = true;
      this.openPopup = false;
    },
    getValidationMessage(key, value) {
      switch (key) {
        case 'firstName':
          return [value[0].replace('firstName', 'prénom')];
        case 'lastName':
          return [value[0].replace('lastName', 'nom')];
        case 'email':
          return [value[0].replace('email', 'email')];
        case 'phone':
          return [value[0].replace('phone', 'numéro de téléphone')];
        case 'fonction':
          return [value[0].replace('fonction', 'fonction')];
        case 'position':
          return [value[0].replace('position', 'position')];
        case 'address':
          return [value[0].replace('address', 'adresse')];
        case 'postalCode':
          return [value[0].replace('postalCode', 'code postale')];
        case 'city':
          return [value[0].replace('city', 'ville')];
        default:
          return value;
      }
    },
    download(register) {
      this.$snackbar.showAlertMessage({
        message: "Téléchargement en cours...",
        type: "info",
      });
      ApiService.post("media/download", {
        collection: "processing-registers",
        entityId: register.id,
        entityType: "Module",
        entityField: "registerActivityDocumentName",
      })
      .then(({ data }) => {
        const link = window.document.createElement('a');
        link.href = data;
        link.setAttribute('download', register.registerActivityDocumentName);
        link.click();
      })
      .catch(error => {
        console.log(error);
        this.$snackbar.showAlertMessage({
          message: "Une erreur s'est produite lors de la récupération du document",
          type: "error",
        });
      })
    }
  },
  watch: {
    word() {
      this.pagination()
    },
    orderby() {
        this.pagination()
    },
    orderType() {
        this.pagination()
    },
    page() {
      this.choosedPage = parseInt(this.page);
        this.pagination()
    }
  }
}
</script>

<style lang="scss">
.slide-fade-enter-active {
  animation: slide-fade .8s;
}

.slide-fade-leave-active {
  display: none;
}

@keyframes slide-fade {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }

}
</style>

<style lang="scss" scoped>
.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 0;
  border: 1px solid #14477E;
  position: absolute;
  z-index: 1000;
  top: 1.44rem;
  right: 2rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext li {
  padding: 0.5rem 1rem;
}

.tooltip .tooltiptext li:hover {
  background-color: rgba(206, 206, 206, 0.39);
}
</style>
