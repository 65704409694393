<template>
  <div id="collaborateurs">
    <div
      class="tw-flex tw-align-start tw-justify-between"
      id="mes-partages-header"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Mes collaborateurs"
        class="tw-mb-2"
        description=""
      />
    </div>
    <CollaborateursTableComponent />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import CollaborateursTableComponent from "@/features/company/components/Collaborateurs/CollaborateursTableComponent";
export default {
  name: "MonEntrepriseCollaborateursPage",
  components: {
    EntrepriseHeaderBlockTextComponent,
    CollaborateursTableComponent,
  },
};
</script>
