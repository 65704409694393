<template>
  <div class="tw-pt-8 tw-px-1" id="dashboard">
    <EntrepriseHeaderBlockTextComponent
      title="Mon dashboard"
      description="Monitorez votre conformité en temps réel !"
    />
    <template v-if="loading">
      <content-placeholders class="tw-mt-8">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <DashboardInvitationBlock />
      <div v-if="dashBoardDataExist" class="tw-mt-6 tw-pb-2 tw-grid md:tw-grid-cols-3 tw-grid-cols-1 md:tw-grid-flow-col tw-gap-4">
        <div class="md:tw-col-span-2">
          <div v-if="isFreePlan" class="tw-h-auto" id="sous-traitants-container">
            <SubContractorBolckOld
                :subConstractorTotal="subCobstractorData.subConstractorTotal"
                :subConstractorPercentage="subCobstractorData.subConstractorPercentage"
                :barchartData="subCobstractorData.barchartData"
                :invitationObject="subCobstractorData.invitationObject"
                :evaluateObject="subCobstractorData.evaluateObject"
                :avgObject="subCobstractorData.avgObject"
            />
          </div>
          <div v-else class="tw-h-auto" id="sous-traitants-container">
            <SubContractorBolck
                :collaborator="collaboratorDataDashboard"
            />
          </div>
        </div>
        <div
            class="tw-col-span-1 tw-p-6 tw-text-center"
            id="sous-traitants-score-container"
        >
          <SubConstractorBlockNews />
        </div>
        <!-- <div
            class="tw-col-span-1 tw-p-6 tw-text-center"
            id="sous-traitants-score-container"
            v-if="subCobstractorData.scoreProgressData.length > 0"
        >
          <SubContractorScoreBlock
              :progressData="subCobstractorData.scoreProgressData"
          />
        </div> -->

      </div>
    </template>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import DashboardInvitationBlock from "@/features/company/components/Dashboard/DashboardInvitationBlock";
import SubContractorBolck from "@/features/company/components/Dashboard/SubContractorBolck";
// import SubContractorBolck from "@/features/company/components/Dashboard/SubContractorBolckOld";
import SubConstractorBlockNews from "@/features/company/components/Dashboard/SubConstractorBlockNews";
// import SubContractorScoreBlock from "@/features/company/components/Dashboard/SubContractorScoreBlock";
import {getDashboardData} from "@/features/company/services/appApi";
import SubContractorBolckOld from "@/features/company/components/Dashboard/SubContractorBolckOld";
import moment from "moment";
export default {
  name: "DashboardIndexPage",
  computed: {
    dashBoardDataExist() {
      return true;
    },
    isFreePlan() {
      if (this.$store.getters.getAuthUser) {
        return this.$store.getters.getAuthUser.workInCompany.planPrice ? this.$store.getters.getAuthUser.workInCompany.planPrice.price === 0 : true;
      } else {
        return true;
      }
    },
    referer() {
      return this.$store.state.referrer;
    },
  },
  components: {
    SubContractorBolckOld,
    SubConstractorBlockNews,
    SubContractorBolck,
    // SubContractorScoreBlock,
    DashboardInvitationBlock,
    EntrepriseHeaderBlockTextComponent
  },
  mounted() {
    this.fetchData();
  },


  data() {
    return {
      show:false,
      loading: false,
      subCobstractorData: {
        subConstractorTotal: "",
        subConstractorPercentage: "",
        barchartData: [],
        invitationObject: { },
        evaluateObject: { },
        avgObject: { },
        scoreProgressData: null,
        data:[]
      },
        collaboratorDataDashboard:{},
    };
  },
  methods: {
    // fetchData() {
    //   this.loading = true;
    //   getDashboardData()
    //     .then(resp => {
    //       this.collaboratorDataDashboard=resp.data.newCollaboratorInvitations
    //     })
    //     .catch(({ response }) => {
    //       this.$snackbar.showAlertMessage({
    //         message: response.data.message,
    //         type: "error",
    //       });
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    //
    // },
    fetchData() {
      this.loading = true;
      getDashboardData()
        .then(resp => {
          let chart = [];
          let subcontractors = [];

          this.collaboratorDataDashboard = resp.data.newCollaboratorInvitations;

          this.subCobstractorData.subConstractorTotal = resp.data.newSubcontractorsOfCurrentMonth.totalSubcontractorsOfCurrentMonth ? resp.data.newSubcontractorsOfCurrentMonth.totalSubcontractorsOfCurrentMonth + '' : '0';

          this.subCobstractorData.subConstractorPercentage = resp.data.newSubcontractorsOfCurrentMonth.newSubcontractorsPercent ? resp.data.newSubcontractorsOfCurrentMonth.newSubcontractorsPercent + '' : '0';

          this.subCobstractorData.avgObject = {
            total: resp.data.avgEvaluatedQuizzes !== null ?  Number(resp.data.avgEvaluatedQuizzes).toFixed() + '' : '0',
            progress: `${Number(resp.data.avgEvaluatedQuizzes).toFixed()}`
          }

          this.subCobstractorData.evaluateObject = {
            total: resp.data.totalEvaluatedInvitations !== null ? resp.data.totalEvaluatedInvitations  + '' : '',
            progress: '45'
          }

          this.subCobstractorData.invitationObject = {
            total: resp.data.totalSentInvitations !== null ? resp.data.totalSentInvitations  + '' : '',
            progress: '50'
          }

          resp.data.invitationsStatistics.forEach(item => {
            let mounth = item.month !== null ? item.month.charAt(0).toUpperCase() +  item.month.charAt(1) +  item.month.charAt(2) : '';
            chart.push({
              mounth: mounth,
              invitation: item.totalSentInvitations !== null ? Number(item.totalSentInvitations) : 0,
              reponse: item.totalRespondedInvitations !== null ? Number(item.totalRespondedInvitations) : 0,
            });
          });
          this.subCobstractorData.barchartData = chart;

          resp.data.subcontractors.forEach(item => {
            let color = '';
            if (item.subcontractor.quiz.status === 'OK_GO') {
              color = '#558C66';
            }
            else if (item.subcontractor.quiz.status === 'GO_SR_A' || item.subcontractor.quiz.status === 'GO_SR_B' ) {
              color = '#D49725';
            }
            else {
              color = '#BB454C';
            }
            let date = item.subcontractor.quiz.createdAt !== null ?  moment(item.subcontractor.quiz.createdAt).format('DD/MM/YYYY') : '';
            let name = item.subcontractor.tradeName !== null ? item.subcontractor.tradeName : '';
            let value = item.subcontractor.quiz.scorePercent !== null ?  item.subcontractor.quiz.scorePercent.toString().split('.')[0] : '0';

            subcontractors.push({ name, date, value, color });
          });

          this.subCobstractorData.scoreProgressData = subcontractors;

        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });

    },
  },
};
</script>
<style scoped></style>
