<template>
  <div style="width: 90%; margin: 0 auto; letter-spacing: 0.5px">
    <p
      style="
        text-align: center;
        font-family: 'Calibri', sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        border: 1px solid black;
        padding: 5px 0;
      "
    >
      Nomination des membres du Comité de Violation de Données personnelles
    </p>
    <br /><br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      Chères Collaboratrices, chers collaborateurs,<br /><br />

      Afin de satisfaire aux exigences du Règlement Général pour la Protection
      des Données Privées, nous avons procédé à la nomination des membres du
      Comité de « Violation des données personnelles » animé par notre
      <select-box @change="(e)=>menuBlueSelectedItem=e" v-model="menuBlueSelectedItem" :items="data.menuBlue" v-if="data.show">
        <option disabled selected>sélectionnez une valeur</option>
      </select-box>
      &nbsp;<span v-if="!data.show">{{ menuBlueSelectedItem }}</span>
      &nbsp;<span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.prenomYellow }}</span>
      &nbsp;<span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.nomYellow }}</span
      >, qui est placé sous mon autorité directe.<br /><br />
      Les personnes concernées sont : <br /><br />
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.nomGouvernantCompteClient }}</span
      ><br /><br />
      &nbsp;<span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.prenomYellow }}</span>
      &nbsp;<span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.nomYellow }}</span
      >,       
      <select-box @change="(e)=>menuBlueSelectedItem=e" v-model="menuBlueSelectedItem" :items="data.menuBlue" v-if="data.show">
        <option disabled>sélectionnez une valeur</option>
      </select-box>
      &nbsp;<span v-if="!data.show">{{ menuBlueSelectedItem }}</span><br /><br />
    </p>

    <div id="input-container">
      <div class="input-group" v-for="(row, index) in rows" :key="index">
        <text-field placeholder="nom" style="width:100px" v-model="row.nom" v-if="data.show"/>
        &nbsp;<span v-if="!data.show"> {{ row.nom }} </span>
        <text-field placeholder="prénom" style="width:100px" v-model="row.prenom" v-if="data.show"/>
        &nbsp;<span v-if="!data.show"> {{ row.prenom }} </span>
        <text-field placeholder="fonction" style="width:100px" v-model="row.fonction" v-if="data.show"/>
        &nbsp;<span v-if="!data.show"> {{ row.fonction }} </span>
        <text-field placeholder="email" type="email" style="width:100px" v-model="row.email" v-if="data.show"/>
        &nbsp;<span v-if="!data.show"> {{ row.email }} </span>
        &nbsp;<span v-if="data.show" style="margin-left: 10px; display: flex;justify-content: center;align-items: center;">
          <button  v-if="index==(rows.length-1)" @click="addNewRow">+</button>
          <button  v-else @click="deleteRow(index)"> - </button>
        </span>
      </div>
    </div>
    <br /><br />

    À ce titre ces personnes seront amenées à contribuer au respect par
    l’Entreprises de ses obligations vis-à-vis de son autorité de contrôle et
    vis-à-vis de ses clients en cas de violation de l’intégrité de ses systèmes.
    <br />
    Elles devront permettre notamment à notre 
    <select-box @change="(e)=>menuBlueSelectedItem=e" v-model="menuBlueSelectedItem" :items="data.menuBlue" v-if="data.show">
        <option disabled>sélectionnez une valeur</option>
      </select-box>
    &nbsp;<span v-if="!data.show">{{ menuBlueSelectedItem }}</span>
    de notifier toute
    violation de données à caractère personnel dans les délais impartis et
    accompagner cette notification de toute documentation utile. <br />
    <ul style="list-style: '- '; margin-left: 10px">
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        La description de la nature de la violation de données à caractère
        personnel y compris, si possible, les catégories et le nombre
        approximatif de personnes concernées par la violation et les catégories
        et le nombre approximatif d'enregistrements de données à caractère
        personnel concernés ;
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        Le nom et les coordonnées de notre 
      <select-box @change="(e)=>menuBlueSelectedItem=e" v-model="menuBlueSelectedItem" :items="data.menuBlue" v-if="data.show">
        <option disabled>sélectionnez une valeur</option>
      </select-box>
      &nbsp;<span v-if="!data.show">{{ menuBlueSelectedItem }}</span>
        ou d'un autre point de
        contact auprès duquel des informations supplémentaires peuvent être
        obtenues ;
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        La description des conséquences probables de la violation de données à
        caractère personnel ;
      </li>
      <li style="font-family: 'Calibri', sans-serif; font-size: 15px">
        La description des mesures prises par
        &nbsp;<span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span>
        ou qu’elle propose de prendre pour remédier à la violation de données à
        caractère personnel, y compris, le cas échéant, les mesures pour en
        atténuer les éventuelles conséquences négatives.
      </li>
    </ul>
    <br /><br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      Le comité de Pilotage RGPD a vocation à définir chaque année l’engagement
      RGPD de l’Entreprise après présentation par le Délégué à la Protection des
      Données de son rapport annuel.<br /><br /><br />

      &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;<span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.ville }}</span
      >, le
      &nbsp;<span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.dateRouge }}</span
      ><br /><br />
      &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.corporateOfficer }}</span
      ><br /><br /><br /><br />
    </p>
  </div>
</template>

<script>
import selectBox from './components/selectBox.vue';
import TextField from './components/textField.vue';
export default {
  components: { selectBox, TextField },
  name: "MembreDuComiteBataBreach",
  props:{
    data:{
      type:Object,
      default:()=>{}
    },
    oldReference:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return {
      menuBlueSelectedItem:'',
      rows:[
        {
          nom:"",
          prenom:"",
          fonction:"",
          email:""
        }
      ]
    }
  },
  mounted() {
    if(!!this.oldReference && Object.keys(this.oldReference)) {
      const {menuBlueSelectedItem, rows} = this.oldReference
      this.menuBlueSelectedItem = menuBlueSelectedItem;
      this.rows = rows.length?rows:this.rows;
    }
  },
  methods:{
    addNewRow(){
      this.rows.push({
        nom:"",
        prenom:"",
        fonction:"",
        email:""
      })
    },
    deleteRow(index){
      this.rows.splice(index,1)
    }
  },
  watch:{
    rows:{
      handler(val){
        this.$store.dispatch("handleChildren",val);
        this.$store.dispatch('handleReferenceData',{
          menuBlueSelectedItem:this.menuBlueSelectedItem,
          rows:val
        })
      },
      deep:true
    },
    menuBlueSelectedItem(val){
      this.$store.dispatch('handleReferenceData',{
        menuBlueSelectedItem:val,
        rows:this.rows
      })
    }
  }
};
</script>

<style></style>
