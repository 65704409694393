<template>
    <div id="reference-add-container">
      <signup-secondary-text-component class="subheadertitle">
          {{ documentSelected.title }}
        </signup-secondary-text-component>
        <div class="tw-flex tw-justify-between tw-items-center subtitle" style="width: 175px;">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.98522 0.955248C7.82457 0.943387 7.66353 0.937446 7.50244 0.937436V0C7.68668 8.98322e-05 7.87086 0.00696903 8.05459 0.0206236L7.98522 0.955248ZM9.86384 1.37709C9.56334 1.26114 9.25472 1.16745 8.94047 1.0968L9.14577 0.181863C9.50481 0.262482 9.85822 0.36935 10.2013 0.502466L9.86384 1.37709ZM11.1481 2.04267C11.0141 1.95329 10.8768 1.86886 10.7366 1.78957L11.1988 0.973996C11.5193 1.15561 11.826 1.36052 12.1165 1.58708L11.54 2.32672C11.4129 2.22756 11.2822 2.13313 11.1481 2.04361V2.04267ZM12.8674 3.72068C12.682 3.45724 12.4774 3.20783 12.2552 2.97449L12.9339 2.32765C13.1871 2.59482 13.4214 2.88074 13.6342 3.18072L12.8674 3.72068ZM13.5648 4.9881C13.5033 4.83948 13.4364 4.69314 13.3642 4.54938L14.2014 4.12753C14.3669 4.45665 14.5079 4.79756 14.6232 5.14746L13.7326 5.44088C13.6823 5.28795 13.6263 5.13692 13.5648 4.9881ZM14.0617 7.33825C14.0541 7.01609 14.0228 6.69493 13.9679 6.37738L14.8913 6.21801C14.9541 6.57986 14.9907 6.94734 15.0001 7.31482L14.0626 7.33825H14.0617ZM13.9389 8.78003C13.9698 8.62066 13.9951 8.46224 14.0148 8.30194L14.9457 8.41724C14.9007 8.78298 14.8286 9.14489 14.7301 9.49998L13.8264 9.24968C13.8695 9.09501 13.907 8.93846 13.9389 8.78003ZM13.0464 11.0102C13.2189 10.7383 13.3708 10.4534 13.502 10.159L14.3588 10.5387C14.2089 10.8761 14.0354 11.2005 13.8386 11.5117L13.0464 11.0102ZM12.1427 12.1398C12.2571 12.0254 12.3668 11.9073 12.4709 11.7854L13.1814 12.3976C13.0611 12.5371 12.9357 12.6722 12.8055 12.8026L12.1427 12.1398Z" fill="black"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50242 0.937436C6.42332 0.937514 5.36089 1.20371 4.40923 1.71245C3.45758 2.22119 2.64607 2.95677 2.04659 3.85403C1.44711 4.7513 1.07816 5.78256 0.97242 6.85647C0.866677 7.93037 1.02741 9.01379 1.44037 10.0107C1.85334 11.0077 2.50579 11.8874 3.33994 12.572C4.1741 13.2566 5.16421 13.7249 6.22257 13.9354C7.28093 14.146 8.37488 14.0923 9.40753 13.7791C10.4402 13.4658 11.3796 12.9028 12.1427 12.1398L12.8055 12.8026C11.9334 13.6751 10.8596 14.3191 9.67918 14.6774C8.49875 15.0358 7.24816 15.0974 6.0382 14.8569C4.82825 14.6164 3.69629 14.0812 2.74263 13.2987C1.78897 12.5161 1.04305 11.5105 0.570953 10.3708C0.0988595 9.23103 -0.0848285 7.99247 0.0361643 6.76479C0.157157 5.53712 0.579095 4.35823 1.26459 3.3326C1.95009 2.30697 2.87798 1.46625 3.96606 0.884942C5.05413 0.303631 6.26879 -0.000326914 7.50242 2.63849e-07V0.937436Z" fill="black"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.03366 2.81226C7.15797 2.81226 7.27719 2.86164 7.36509 2.94954C7.45299 3.03744 7.50238 3.15666 7.50238 3.28097V8.16502L10.5472 9.9049C10.652 9.96812 10.7279 10.0698 10.7587 10.1883C10.7895 10.3067 10.7727 10.4325 10.712 10.5388C10.6513 10.6451 10.5514 10.7234 10.4337 10.757C10.316 10.7906 10.1899 10.7768 10.0822 10.7186L6.80118 8.84372C6.72944 8.80274 6.6698 8.74353 6.62832 8.67208C6.58683 8.60063 6.56497 8.51949 6.56494 8.43687V3.28097C6.56494 3.15666 6.61432 3.03744 6.70223 2.94954C6.79013 2.86164 6.90935 2.81226 7.03366 2.81226Z" fill="black"/>
            </svg>
            <span>Historique de diffusion</span>
        </div>
        <div class="tw-mt-12 font-calibri show-refrence-box">
            <div id="incident-table">
            <content-placeholders v-if="loading_table">
              <content-placeholders-text :lines="10" />
            </content-placeholders>
            <!-- table  -->
            <v-simple-table v-else>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="tw-text-left tw-text-xs">Version</th>
                    <th class="tw-text-left tw-text-xs">Date</th>
                    <th class="tw-text-left tw-text-xs">Visualisation</th>
                    <th class="tw-text-left tw-text-xs">Impression</th>
                    <th class="tw-text-left tw-text-xs">Edition</th>
                    <th class="tw-text-left tw-text-xs">Collaborateurs</th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="el in items" :key="el.id">
                    <td class="tw-text-left tw-text-xs">{{ el.version }}</td>
                    <td class="tw-text-left tw-text-xs">
                      {{ el.createdAt | formatDate}}
                    </td>
                    <td>
                      <span @click="goTo(el)" class="tw-cursor-pointer">
                        <div class="tw-flex tw-justify-center tw-items-center">
                            <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.6899 8.44877C15.6899 9.40908 15.3084 10.3301 14.6294 11.0091C13.9503 11.6882 13.0293 12.0696 12.069 12.0696C11.1087 12.0696 10.1877 11.6882 9.50865 11.0091C8.8296 10.3301 8.44812 9.40908 8.44812 8.44877C8.44812 7.48845 8.8296 6.56746 9.50865 5.88841C10.1877 5.20937 11.1087 4.82788 12.069 4.82788C13.0293 4.82788 13.9503 5.20937 14.6294 5.88841C15.3084 6.56746 15.6899 7.48845 15.6899 8.44877Z" :fill="referer.theme.ctaBgColor"/>
                                <path d="M24.0106 7.90922C21.406 2.69877 16.7809 0 12.069 0C7.3582 0 2.73192 2.69877 0.127293 7.90922C0.0435802 8.07675 0 8.26145 0 8.44873C0 8.63601 0.0435802 8.82072 0.127293 8.98824C2.73192 14.1987 7.35699 16.8975 12.069 16.8975C16.7797 16.8975 21.406 14.1987 24.0106 8.98824C24.0944 8.82072 24.1379 8.63601 24.1379 8.44873C24.1379 8.26145 24.0944 8.07675 24.0106 7.90922ZM12.069 14.4835C8.4855 14.4835 4.82479 12.5283 2.57018 8.44873C4.82479 4.3692 8.48429 2.41392 12.069 2.41392C15.6524 2.41392 19.3132 4.3692 21.5678 8.44873C19.3132 12.5283 15.6524 14.4835 12.069 14.4835Z" :fill="referer.theme.ctaBgColor"/>
                            </svg>
                        </div>
                      </span>
                    </td>
                    <td>
                      <span @click="printPDF(el)" class="tw-cursor-pointer" id="print">
                        <div class="tw-flex tw-justify-center tw-items-center">
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9719 7.22114H18.5689V5.15793C18.5913 4.63422 18.4052 4.12298 18.0513 3.73624C17.6975 3.34951 17.2047 3.11882 16.6811 3.09473H8.0775C7.55386 3.11882 7.06112 3.34951 6.70726 3.73624C6.3534 4.12298 6.16727 4.63422 6.18967 5.15793V7.22114H4.78668C4.0607 7.22659 3.36647 7.51952 2.85602 8.03577C2.34557 8.55203 2.0605 9.24953 2.06325 9.97552V16.846C2.0605 17.572 2.34557 18.2695 2.85602 18.7857C3.36647 19.302 4.0607 19.5949 4.78668 19.6004H5.67386C5.67386 20.1476 5.89124 20.6724 6.27816 21.0593C6.66509 21.4462 7.18987 21.6636 7.73707 21.6636H17.0215C17.5687 21.6636 18.0935 21.4462 18.4804 21.0593C18.8673 20.6724 19.0847 20.1476 19.0847 19.6004H19.9719C20.6979 19.5949 21.3921 19.302 21.9026 18.7857C22.413 18.2695 22.6981 17.572 22.6953 16.846V9.97552C22.6981 9.24953 22.413 8.55203 21.9026 8.03577C21.3921 7.51952 20.6979 7.22659 19.9719 7.22114ZM8.25287 5.15793H16.5057V7.22114H8.25287V5.15793ZM7.73707 19.6004V15.474H17.0215V19.6004H7.73707ZM20.6321 16.846C20.6349 17.0248 20.5672 17.1976 20.4437 17.3269C20.3201 17.4563 20.1507 17.5318 19.9719 17.5372H19.0847V15.474C19.0847 14.9268 18.8673 14.402 18.4804 14.0151C18.0935 13.6281 17.5687 13.4108 17.0215 13.4108H7.73707C7.18987 13.4108 6.66509 13.6281 6.27816 14.0151C5.89124 14.402 5.67386 14.9268 5.67386 15.474V17.5372H4.78668C4.60791 17.5318 4.43844 17.4563 4.3149 17.3269C4.19136 17.1976 4.12367 17.0248 4.12646 16.846V9.97552C4.12367 9.79669 4.19136 9.62394 4.3149 9.49461C4.43844 9.36527 4.60791 9.28975 4.78668 9.28435H19.9719C20.1507 9.28975 20.3201 9.36527 20.4437 9.49461C20.5672 9.62394 20.6349 9.79669 20.6321 9.97552V16.846Z" :fill="referer.theme.ctaBgColor"/>
                            </svg>
                        </div>

                      </span>
                    </td>
                    <td>
                      <span @click="updateFile(el)" :class="{'tw-cursor-pointer':maxId==el.id}">
                        <div class="tw-flex tw-justify-center tw-items-center">
                            <svg v-if="maxId==el.id" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.4745 3.40801L15.5917 5.52523M14.8358 1.54289L9.1086 7.27004C8.8131 7.56561 8.6116 7.94205 8.5296 8.35189L8 11L10.6481 10.4704C11.0579 10.3884 11.4344 10.1869 11.7299 9.89139L17.4571 4.16423C18.181 3.44037 18.181 2.26676 17.4571 1.5429C16.7332 0.81904 15.5596 0.81903 14.8358 1.54289Z" :stroke="referer.theme.ctaBgColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16 13V16C16 17.1046 15.1046 18 14 18H3C1.89543 18 1 17.1046 1 16V5C1 3.89543 1.89543 3 3 3H5.99999" :stroke="referer.theme.ctaBgColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <svg v-else width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.4745 3.40801L15.5917 5.52523M14.8358 1.54289L9.1086 7.27004C8.8131 7.56561 8.6116 7.94205 8.5296 8.35189L8 11L10.6481 10.4704C11.0579 10.3884 11.4344 10.1869 11.7299 9.89139L17.4571 4.16423C18.181 3.44037 18.181 2.26676 17.4571 1.5429C16.7332 0.81904 15.5596 0.81903 14.8358 1.54289Z" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M16 13V16C16 17.1046 15.1046 18 14 18H3C1.89543 18 1 17.1046 1 16V5C1 3.89543 1.89543 3 3 3H5.99999" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                        </div>
                      </span>
                    </td>
                    <td class="tw-text-left tw-text-xs">
                      {{ el.collaboratorsCount }}
                    </td>
                  </tr>
                </tbody>

                <tfoot class="text-center" v-if="length > 1">
                  <tr>
                    <td colspan="6">
                      <footer-table-pagination-component :limit="limit" :choosingPage="choosedPage" :length="length" :page="page" @changeLimitAndPage="changeLimitAndPage" @changePage="changePage" />
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
            <!-- end table  -->
          </div>
        </div>

      <v-dialog v-if="showReferenceDocument" v-model="showReferenceDocument" max-width="800px">
        <v-card elevation="0">
          <v-card-title class="headline"></v-card-title>
          <v-card-text>
            <iframe :src="referenceDocumentUrl" width="100%" height="600px"></iframe>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="showReferenceDocument = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
import SignupSecondaryTextComponent from '../../../../../../auth/components/SignupSecondaryTextComponent.vue'
import moment from 'moment'
import FooterTablePaginationComponent from '@/components/common/FooterTablePaginationComponent.vue'
import {getReferenceByDocument} from '../../../../../services/appApi'
import { serverConfig } from "@/config/serverConfig";
import ApiService from '@/services/api.service'

export default {
    name:'referenceVersionList',
    components: { SignupSecondaryTextComponent, FooterTablePaginationComponent },
    props:{
    documentSelected: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return{
      src:null,
      items: [],
      page: 1,
      selectedItem:{},
      perPage: 10,
      limit:10,
      choosedPage: 1,
      length: 1,
      loading_table: false,
      showReferenceDocument: false,
      referenceDocumentUrl: '',
    }
  },
  computed:{
    referer(){
        return this.$store.state.referrer;
    },
    serverUrl(){
      return serverConfig;
    },
    maxId(){
      return this.items.length>0?this.items[0].id:0
    }
  },
  filters: {
    formatDate(date) {
      return date?moment(date).format("DD/MM/YYYY"):'';
    },
  },
  mounted(){
    this.getReferencesByDocumentId()
  },
  methods:{
    updateFile(item){
      if(this.maxId==item.id){
        this.documentSelected.html = item.template;
        this.documentSelected.answers = item.answers.length ?JSON.parse(item.answers[0].answers):{};
        this.documentSelected.done=false
        this.documentSelected.update=true
      }
    },
    goTo(item) {
      ApiService.post("media/download", {
        collection: "references",
        entityId: item.id,
        entityType: "Reference",
        entityField: "path",
      })
      .then(({ data }) => {
        this.referenceDocumentUrl = data;
        this.showReferenceDocument = true;
      })
      .catch(error => {
        console.log(error);
        this.$snackbar.showAlertMessage({
          message: "Une erreur s'est produite lors de la récupération du fichier",
          type: "error",
        });
      })
    },
    changeLimitAndPage(obj) {
      this.limit =parseInt(obj.limit) ;
      this.page == obj.page?this.getReferencesByDocumentId():this.changePage(obj.page);
    },
    changePage(page){
      this.page=page;
      setTimeout(()=>{
        document.querySelector('#reference-add-container').scrollIntoView({
          behavior: 'smooth'
        });
      },200)
    },
    getReferencesByDocumentId(){
      getReferenceByDocument(this.documentSelected.id, this.page,this.limit)
        .then((res)=> {
          this.items = res.data.items
          this.page = res.data.meta.currentPage;
          this.length = res.data.meta.totalPages;
        })
        .catch((err)=> {
          console.log(err)
        })
    },
    printPDF(el) {
      const mywindow = window.open('','PRINT', "height=650,width=900,top=100,left=150,display:none;");
      mywindow.document.write(el.template) ;
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
      mywindow.close();
    }
  },
  watch: {
    orderby() {
        this.getReferencesByDocumentId()
    },
    orderType() {
        this.getReferencesByDocumentId()
    },
    page() {
      this.choosedPage = this.page
      this.getReferencesByDocumentId()
    }
  }
  }
</script>

<style lang="scss" scoped>
.subtitle{
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
}
.subheadertitle{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
}
</style>