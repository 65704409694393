<template>
  <div id="subconstractorscore-block">
    <span class="score-dashboard-title">Actualités - Cnil & RGPD</span>
    
    <div v-if="!isLoading" class="tw-flex tw-flex-col tw-h-96 news-block tw-space-y-6 tw-flex-grow tw-mt-4">
      <div class="tw-flex tw-justify-center tw-items-center tw-w-full" v-for="(item, index) in data" :key="index">
        <a :href="item.link" class="tw-w-full" target="_blank">
          <div class="tw-flex tw-justify-center tw-w-full tw-space-x-2">
            <div>
              <img :src="item.enclosures[0].url" :alt="item.title" class="tw-h-14 tw-rounded-lg" width="79" height="64">
            </div>
            <div class="tw-flex tw-flex-col tw-space-y-1 tw-w-2/4">
              <div class="tw-text-xs tw-text-left tw-font-semibold" :title="item.title">{{ item.title.substring(0,
                30)}}..</div>
              <span class="tw-text-xs tw-text-left tw-text-gray-400">Le monde - {{ getDateOfRss(item) }}</span>
            </div>
            <div class="tw-flex tw-justify-end">
              <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 1L5.5 6L0.5 11" stroke="black" />
              </svg>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div v-else class="tw-flex tw-justify-center tw-items-center tw-h-96">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { getCnilRssFlux } from "@/features/company/services/appApi";
import moment from 'moment'

export default {
  name: "SubContractorScoreBlock",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  mounted() {
    this.getRssFlux()
  },
  data() {
    return {
      data: [],
      isLoading: true
    }
  },
  methods: {
    getDateOfRss(item) {
      return moment(item.created).format('DD/MM/YYYY')
    },
    getRssFlux() {
      getCnilRssFlux()
        .then(({ data }) => this.data = data.items)
        .catch(_ => console.log(_))
        .finally(() => setTimeout(() => this.isLoading = false, 1500))
    }
  }
}
</script>

<style lang='scss' scoped>
.news-block {
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>