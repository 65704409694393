<template>
  <div class="tw-pt-8 tw-px-1 tw-mt-8">
    <v-btn class="tw-mb-8 rgpd-detail-retour-btn" color="#25282B" plain :to="{ name: 'Gouvernance.Docutheque.List' }">
      Retour
    </v-btn>
    <div class="tw-flex tw-align-start tw-justify-between">
      <EntrepriseHeaderBlockTextComponent title="Docuthèque" />
    </div>
    <v-row>
      <v-col cols="12" md="11" v-if="!isLoading">
        <div class="tw-w-full tw-flex tw-items-center tw-justify-between tw-my-6">
          <SignupSecondaryTextComponent>
            <b>{{ document.name }}</b>
          </SignupSecondaryTextComponent>
          <a :style="{ color: theme.ctaBgColor }" :href="documentFile" class="rgpd-detail-button" target="_blank" :download="document.path">Télécharger</a>
        </div>

        <iframe v-if="document.path.includes('.pdf') || document.path.includes('.PDF')" :src="documentFile" class="tw-w-full tw-mx-auto" style="height: 70vh;" frameborder="0"></iframe>
        <img v-if="document.path.includes('.jpg') || document.path.includes('.jpeg')" :src="documentFile" class="tw-mx-auto" style="height: 70vh;" />
      </v-col>

      <content-placeholders v-if="isLoading" class="tw-w-full tw-my-6">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </v-row>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import { getDocumentation } from "@/features/company/services/appApi";
import ApiService from "@/services/api.service";

export default {
  name: 'GouvernanceDocuthequePreviewPage',
  components: {
    EntrepriseHeaderBlockTextComponent,
    SignupSecondaryTextComponent,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      document: {},
      documentFile: null,
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  mounted() {
    this.getDocumentationFile();
  },
  methods: {
    getDocumentationFile() {
      getDocumentation(this.id)
        .then(({ data }) => {
          this.document = data

          ApiService.post("media/download", {
            collection: "documentations",
            entityId: this.id,
            entityType: "Documentation",
            entityField: "path",
          })
          .then(({ data }) => this.documentFile = data)
          .catch(error => {
            console.log(error);
            this.$snackbar.showAlertMessage({
              message: "Une erreur s'est produite lors de la récupération de la documentation",
              type: "error",
            });
          })
        })
        .catch(error => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur s'est produite lors de la récupération de la documentation",
            type: "error",
          });
        })
        .finally(() => setTimeout(() => {
          this.isLoading = false;
        }, 500));
    },
  },
}
</script>