<template>
  <div style="width: 90%; margin: 0 auto; letter-spacing: 0.5px">
    <p
      style="
        text-align: center;
        font-family: 'Calibri', sans-serif;
        font-size: 18px;
        line-height: 20px;
      "
    >
      Charte d'utilisation des ressources informatiques de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
    </p>
    <br />
    <br />
    <h5
      style="
        font-family: 'Calibri', sans-serif;
        text-decoration: underline;
        font-weight: bold;
      "
    >
      Préambule.
    </h5>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >,
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{data.structureJuridiqueYellow}}</span>
      met en œuvre un système d’information et de communication nécessaire à
      l’exercice de son activité.<br />
      Elle met ainsi à disposition de ses collaborateurs des outils
      informatiques, et de communication.<br />
      La présente charte définit les conditions d’accès et les règles
      d’utilisation des moyens informatiques et des ressources extérieures via
      les outils de communication de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      ><br />
      Elle a également pour objet de sensibiliser les utilisateurs aux risques
      liés à l’utilisation de ces ressources en termes d’intégrité et de
      confidentialité des informations traitées. Ces risques imposent le respect
      de certaines règles de sécurité et de bonne conduite.<br />
      L’imprudence, la négligence ou la malveillance d’un utilisateur peuvent en
      effet avoir des conséquences graves de nature à engager sa responsabilité
      civile et/ou pénale ainsi que celle de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >.
    </p>
    <br />
    <h5
      style="
        font-family: 'Calibri', sans-serif;
        text-decoration: underline;
        font-weight: bold;
      "
    >
      Protection des données à caractère personnel.
    </h5>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      La loi n°78-17 du 6 janvier 1978 modifiée en 2004 relative à
      l’informatique, aux fichiers et aux libertés définit les conditions dans
      lesquelles des traitements de données à caractère personnel peuvent être
      effectués. <br />
      Elle ouvre aux personnes concernées par les traitements un droit d’accès
      et de rectification des données enregistrées sur leur compte.<br />
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      a désigné
      <span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.prenomYellow }}</span>
      <span
        style="
          margin-left: 3px;
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.nomYellow }}</span>
      comme       
      <select-box @change="(e)=>referenceData.menuBlueSelectedItem=e" v-model="referenceData.menuBlueSelectedItem" :items="data.menuBlue" v-if="data.show">
        <option disabled>sélectionnez une valeur</option>
      </select-box>
      <span v-if="!data.show">{{ referenceData.menuBlueSelectedItem }}</span>
      . à caractère personnel. <br />
      Ce dernier a pour mission de veiller au respect des dispositions de la loi
      n°78-17 du 6 janvier 1978 modifiée.<br />
      Il est obligatoirement consulté par le responsable des traitements
      préalablement à leur création.<br />
      Il recense dans un registre la liste de l’ensemble des traitements de
      données à caractère personnel de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      au fur et à mesure de leur mise en œuvre. <br />
      Cette liste est tenue à disposition de toute personne en faisant la
      demande.<br />
      Elle est également diffusée sur l’intranet de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >.<br />
      Le correspondant veille au respect des droits des personnes (droit
      d’accès, de rectification et d’opposition). <br />
      En cas de difficultés rencontrées lors de l’exercice de ces droits, les
      personnes concernées peuvent saisir
      <span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.prenomYellow }}</span>
      <span
        style="
          margin-left: 3px;
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.nomYellow }}</span
      >,        
      <select-box @change="(e)=>referenceData.menuBlueSelectedItem=e" v-model="referenceData.menuBlueSelectedItem" :items="data.menuBlue" v-if="data.show">
        <option disabled>sélectionnez une valeur</option>
      </select-box>
      <span v-if="!data.show">{{ referenceData.menuBlueSelectedItem }}</span>.
    </p>
    <br />
    <h5
      style="
        font-family: 'Calibri', sans-serif;
        text-decoration: underline;
        font-weight: bold;
      "
    >
      Champs d’application de la charte.
    </h5>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      La présente charte s'applique à tout utilisateur du Système d'Information
      et de communication de la
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      pour l’exercice de ses activités professionnelles. <br />
      L’utilisation à titre privé de ces outils est tolérée, mais doit être
      raisonnable et ne pas perturber le bon fonctionnement du service.<br />
      La charte est diffusée à l’ensemble des utilisateurs par note de service
      et, à ce titre, mise à disposition sur l’intranet de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >. <br />
      Elle est systématiquement remise à tout nouvel arrivant.<br />
      Des actions de communication internes sont organisées régulièrement afin
      d’informer les utilisateurs des pratiques recommandées.<br />
      Quelques définitions :<br />
      On désignera sous le terme « utilisateur » ou « collaborateur » toute
      personne autorisée à accéder aux outils informatiques et aux moyens de
      communication de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      et à les utiliser : employés, stagiaires, intérimaires, personnels de
      sociétés prestataires, visiteurs occasionnels....<br />
      Les termes "outils informatiques et de communication" recouvrent tous les
      équipements informatiques, de télécommunications et de reprographie de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >.
    </p>
    <br />
    <h5
      style="
        font-family: 'Calibri', sans-serif;
        text-decoration: underline;
        font-weight: bold;
      "
    >
      Règles d’utilisation du système d’information de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >.
    </h5>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      Chaque utilisateur accède aux outils informatiques nécessaires à
      l’exercice de son activité professionnelle dans les conditions définies
      par
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >.<br />
    </p>

    <ol style="list-style: decimal">
      <li
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >
        Modalités d’intervention du service de l’informatique interne.<br />
        Le service de l’informatique interne assure le bon fonctionnement et la
        sécurité des réseaux, des moyens informatiques et de communication de
        <span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span
        >. <br />
        Les collaborateurs/personnels de ce service disposent d’outils
        techniques afin de procéder aux investigations et au contrôle de
        l’utilisation des systèmes informatiques mis en place.<br />
        Ils ont accès à l’ensemble des données techniques mais s’engagent à
        respecter les règles de confidentialité applicables aux contenus des
        documents.<br />
        Ils sont assujettis au devoir de réserve et sont tenus de préserver la
        confidentialité des données qu’ils sont amenés à connaître dans le cadre
        de leurs fonctions.<br />
      </li>
      <li
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >
        Authentification.<br />
        L'accès aux ressources informatiques repose sur l’utilisation d'un nom
        de compte ("login" ou identifiant) fourni à l'utilisateur lors de son
        arrivée chez
        <span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span
        >. <br />
        Un mot de passe est associé à cet identifiant de connexion.<br />
        Les moyens d’authentification sont personnels et confidentiels.<br />
        Actuellement, le mot de passe doit être composé de
        <text-field placeholder="Mot de passe" v-model="referenceData.password" v-if="data.show"/>
        <span v-if="!data.show">{{ referenceData.password }}</span>
        (exemple : 8 caractères minimum combinant chiffres, lettres et
        caractères spéciaux) <br />
        Il ne doit comporter ni le nom, prénom ni l’identifiant d’ouverture de
        la session de travail. Il doit être renouvelé régulièrement
        <text-field placeholder="Durée" v-model="referenceData.duration" v-if="data.show"/>
        <span v-if="!data.show">{{ referenceData.duration }}</span>
        (exemple : tous les 3 mois).<br />
      </li>
      <li
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >
        Règles de sécurité.<br />
        Tout utilisateur s’engage à respecter les règles de sécurité suivantes
        :<br />
        - Signaler au service informatique interne de
        <span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span>
        toute violation ou tentative de violation suspectée de son compte réseau
        et de manière générale tout dysfonctionnement.<br />
        - Ne jamais confier son identifiant/mot de passe.<br />
        - Ne jamais demander son identifiant/mot de passe à un collègue ou à un
        collaborateur.<br />
        - Ne pas masquer sa véritable identité.<br />
        - Ne pas usurper l'identité d'autrui.<br />
        - Ne pas modifier les paramétrages du poste de travail.<br />
        - Ne pas installer de logiciels sans autorisation.<br />
        - Ne pas copier, modifier, détruire les logiciels propriétés de [nom de
        l’organisme].<br />
        - Verrouiller son ordinateur dès qu’il quitte son poste de travail.<br />
        - Ne pas accéder, tenter d'accéder, supprimer ou modifier des
        informations qui ne lui appartiennent pas.<br />
        - Toute copie de données sur un support externe est soumise à l’accord
        du supérieur hiérarchique et doit respecter les règles définies par
        <span
          style="font-family: 'Calibri', sans-serif"
        >{{ data.socialClient }}</span
        >.<br />
      </li>
    </ol>
    <span
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      En outre, il convient de rappeler que les visiteurs ne peuvent avoir accès
      au Système d'Information de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      sans l'accord préalable du service informatique interne. Les intervenants
      extérieurs doivent s'engager à faire respecter la présente charte par
      leurs propres salariés et éventuelles entreprises sous-traitantes. Dès
      lors, les contrats signés entre
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      et tout tiers ayant accès aux données, aux programmes informatiques ou
      autres moyens, doivent comporter une clause rappelant cette obligation.
    </span>
    <br />
    <h5
      style="
        font-family: 'Calibri', sans-serif;
        text-decoration: underline;
        font-weight: bold;
      "
    >
      Moyens informatiques.
    </h5>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      1. Configuration du poste de travail.<br />
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      met à disposition de chaque utilisateur un poste de travail doté des
      outils informatiques nécessaires à l’accomplissement de ses fonctions.<br />
      L’'utilisateur ne doit pas :<br />
      - Modifier ces équipements et leur fonctionnement, leur paramétrage, ainsi
      que leur configuration physique ou logicielle.<br />
      - Connecter ou déconnecter du réseau les outils informatiques et de
      communications sans y avoir été autorisé par l’équipe informatique
      interne.<br />
      - Déplacer l’équipement informatique (sauf s’il s’agit d’un « équipement
      nomade »)<br />
      - Nuire au fonctionnement des outils informatiques et de
      communications.<br />
      Toute installation de logiciels supplémentaires (logiciels de consultation
      de fichiers multimédia) est subordonnée à l’accord du service informatique
      interne.<br />
      2. Équipements nomades et procédures spécifiques aux matériels de prêt.<br />
      Équipements nomades.<br />
      On entend par « équipements nomades » tous les moyens techniques mobiles
      (ordinateur portable, imprimante portable, téléphones mobiles ou
      smartphones, CD ROM, clé USB, disques durs externes etc..). <br />
      Quand cela est techniquement possible, ils doivent faire l’objet d’une
      sécurisation particulière, au regard de la sensibilité des documents
      qu’ils peuvent stocker, notamment par chiffrement.<br />
      Quand un ordinateur portable se trouve dans le bureau du collaborateur qui
      en a l’usage, cet ordinateur doit être physiquement attaché à l’aide de
      l’antivol prévu à cet effet (sauf quand l’utilisateur est physiquement
      présent dans son bureau).<br />
      L’utilisation de smartphones pour relever automatiquement la messagerie
      électronique comporte des risques particuliers pour la confidentialité des
      messages, notamment en cas de perte ou de vol de ces équipements. <br />
      Quand ces appareils ne sont pas utilisés pendant quelques minutes, ils
      doivent donc être verrouillés par un moyen adapté de manière à prévenir
      tout accès non autorisé aux données qu’ils contiennent.<br />
      Procédures spécifiques aux matériels de prêt. <br />
      L’utilisateur doit renseigner et signer un registre, tenu par le service
      informatique interne, actant la remise de l’équipement nomade ou encore la
      mise à disposition d’un matériel spécifique pour la tenue d’une réunion
      (vidéoprojecteur). <br />
      Il en assure la garde et la responsabilité et doit informer [nom du
      service ou de la direction compétente] en cas d’incident (perte, vol,
      dégradation) afin qu’il soit procédé aux démarches telles que la
      déclaration de vol ou de plainte. Il est garant de la sécurité des
      équipements qui lui sont remis et ne doit pas contourner la politique de
      sécurité mise en place sur ces mêmes équipements. Le retour du matériel
      est consigné dans le registre.<br />
      3. Internet.<br />
      Les utilisateurs peuvent consulter les sites internet présentant un lien
      direct et nécessaire avec l'activité professionnelle, de quelque nature
      qu’ils soient.<br />
      Toutefois, une utilisation ponctuelle et raisonnable, pour un motif
      personnel, des sites internet dont le contenu n'est pas contraire à la
      loi, l'ordre public, et ne met pas en cause l'intérêt et la réputation de
      l’institution, est admise.<br />
      4. Messagerie électronique.<br />
      Conditions d’utilisation<br />
      La messagerie mise à disposition des utilisateurs est destinée à un usage
      professionnel.<br />
      L'utilisation de la messagerie à des fins personnelles est tolérée si elle
      n'affecte pas le travail du collaborateur ni la sécurité du réseau
      informatique de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >.<br />
      Tout message qui comportera la mention expresse ou manifeste de son
      caractère personnel bénéficiera du droit au respect de la vie privée et du
      secret des correspondances. <br />
      A défaut, le message est présumé professionnel.<br />
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      s’interdit d’accéder aux dossiers et aux messages identifiés comme «
      personnel » dans l’objet de la messagerie du collaborateur.<br />
      L’utilisation de la messagerie électronique doit se conformer aux règles
      d’usage définies par le service informatique interne, et validées par la
      direction :<br />
      - volumétrie de la messagerie,<br />
      - taille maximale de l’envoi et de la réception d’un message,<br />
      - nombre limité de destinataires simultanés lors de l’envoi d’un
      message,<br />
      - gestion de l’archivage de la messagerie.<br />
      Le transfert de messages, ainsi que leurs pièces jointes, à caractère
      professionnel sur des messageries personnelles est soumis aux mêmes règles
      que les copies de données sur supports externes.<br />
      Les collaborateurs peuvent consulter leur messagerie à distance, à l’aide
      d’un navigateur (webmail). <br />
      Les fichiers qui seraient copiés sur l’ordinateur utilisé par le
      collaborateur dans ce cadre doivent être effacés dès que possible de
      l’ordinateur utilisé.<br />
      Consultation de la messagerie<br />
      En cas d'absence d'un agent et afin de ne pas interrompre le
      fonctionnement du service, le service informatique interne de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      peut, ponctuellement transmettre au supérieur hiérarchique un message
      électronique à caractère exclusivement professionnel et identifié comme
      tel par son objet et/ou son expéditeur (cf conditions d’utilisation).
      <br />
      Le supérieur hiérarchique n'a pas accès aux autres messages du
      collaborateur. <br />
      Le collaborateur concerné est informé dès que possible de la liste des
      messages qui ont été transférés.<br />
      En cas d’absence prolongée d’un collaborateur (longue maladie), le chef de
      service peut demander au service informatique, après accord de son
      directeur, le transfert des messages reçus.<br />
      Courriel non sollicité<br />
      Afin de ne pas accentuer davantage l'encombrement du réseau lié à ce
      phénomène, les utilisateurs sont invités à limiter leur consentement
      explicite préalable à recevoir un message de type commercial, newsletter,
      abonnements ou autres, et de ne s'abonner qu'à un nombre limité de listes
      de diffusion notamment si elles ne relèvent pas du cadre strictement
      professionnel.<br />
      5. Téléphone.<br />
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      met à disposition des utilisateurs, pour l’exercice de leur activité
      professionnelle, des téléphones fixes et mobiles.<br />
      L’utilisation du téléphone à titre privé est admise à condition qu’elle
      demeure raisonnable.<br />
      Des restrictions d’utilisation par les collaborateurs des téléphones fixes
      sont mises en place en tenant compte de leurs missions. A titre d’exemple,
      certains postes sont limités aux appels nationaux, d’autres peuvent passer
      des appels internationaux
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      s’interdit de mettre en œuvre un suivi individuel de l’utilisation des
      services de télécommunications. <br />
      Seules des statistiques globales sont réalisées sur l’ensemble des appels
      entrants et sortants. Elle vérifie que les consommations n’excèdent pas
      les limites des contrats passés avec les opérateurs.<br />
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      s’interdit d’accéder à l’intégralité des numéros appelés via
      l’autocommutateur mis en place et via les téléphones mobiles. <br />
      Toutefois, en cas d’utilisation manifestement anormale, le service
      informatique, sur demande de la Direction, se réserve le droit d’accéder
      aux numéros complets des relevés individuels.<br />
      6. L’utilisation des outils informatiques par les représentants du
      personnel.<br />
      Les représentants du personnel au comité d’entreprise (CE) utilisent, dans
      le cadre de leur mandat, les outils informatiques qui leur sont attribués
      pour l’exercice de leur activité professionnelle. Ils disposent d’une
      adresse électronique dédiée.
    </p>
    <br />
    <h5
      style="
        font-family: 'Calibri', sans-serif;
        text-decoration: underline;
        font-weight: bold;
      "
    >
      Administration du système d’information.
    </h5>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      Afin de surveiller le fonctionnement et de garantir la sécurité du système
      d’information de l’entreprise, différents dispositifs sont mis en
      place.<br />
      1. Les systèmes automatiques de filtrage.<br />
      A titre préventif, des systèmes automatiques de filtrage permettant de
      diminuer les flux d'information pour la [nom de l’organisme] et d'assurer
      la sécurité et la confidentialité des données sont mis en œuvre.<br />
      Il s’agit notamment du filtrage des sites Internet, de l’élimination des
      courriels non sollicités, du blocage de certains protocoles (peer to peer,
      messagerie instantanée...).<br />
      2. Les systèmes automatiques de traçabilité.<br />
      Le service informatique de la [nom de l’organisme] opère sans
      avertissement les investigations nécessaires à la résolution de
      dysfonctionnements du système d'information ou de l'une de ses
      composantes, qui mettent en péril son fonctionnement ou son intégrité.<br />
      Il s’appuie pour ce faire, sur des fichiers de journalisation (fichiers «
      logs ») qui recensent toutes les connexions et tentatives de connexions au
      système d'information.<br />
      Ces fichiers comportent les données suivantes : dates, postes de travail
      et objet de l’évènement.<br />
      Le service informatique est le seul utilisateur de ces informations qui
      sont effacées à l’expiration d’un délai de trois mois.<br />

      3. Gestion du poste de travail.<br />
      A des fins de maintenance informatique, le service informatique interne de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      peut accéder à distance à l'ensemble des postes de travail. <br />
      Cette intervention s'effectue avec l'autorisation expresse du
      collaborateur ou de l'utilisateur.<br />
      Dans le cadre de mises à jour et évolutions du système d’information, et
      lorsqu’aucun utilisateur n’est connecté sur son poste de travail, le
      service informatique peut être amené à intervenir sur l’environnement
      technique des postes de travail. <br />
      Il s’interdit d’accéder aux contenus.
    </p>
    <br />
    <h5
      style="
        font-family: 'Calibri', sans-serif;
        text-decoration: underline;
        font-weight: bold;
      "
    >
      Procédure applicable lors du départ de l’utilisateur.
    </h5>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      Lors de son départ, l’utilisateur doit restituer au service de
      l’informatique interne les matériels mis à sa disposition.<br />
      Il doit préalablement effacer ses fichiers et données privées., Toute
      copie de documents professionnels doit être autorisée par le chef de
      service.<br />
      Les comptes et les données personnelles de l’utilisateur sont, en tout
      état de cause, supprimés dans un délai maximum d’un mois après son
      départ.<br />
    </p>
    <br />
    <h5
      style="
        font-family: 'Calibri', sans-serif;
        text-decoration: underline;
        font-weight: bold;
      "
    >
      Responsabilités – sanctions.
    </h5>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      Le manquement aux règles et mesures de sécurité et de confidentialité
      définies par la présente charte est susceptible d’engager la
      responsabilité de l’utilisateur et d’entraîner des sanctions à son
      encontre.<br />
      Des sanctions en interne peuvent être prononcées, elles consistent :<br />
      - dans un premier temps, en un rappel à l’ordre émanant de la Direction
      après avis du service informatique interne, en cas de non-respect des
      règles énoncées par la charte ;<br />
      - dans un second temps, et en cas de renouvellement, après avis du comité
      de pilotage de la conformité., en des sanctions disciplinaires.<br />
      Le non-respect des lois et textes applicables en matière de sécurité des
      systèmes d’information (cf. liste des textes en annexe) est susceptible de
      sanctions pénales prévues par la loi.
    </p>
    <br />
    <h5
      style="
        font-family: 'Calibri', sans-serif;
        text-decoration: underline;
        font-weight: bold;
      "
    >
      Entrée en vigueur de la charte.
    </h5>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      La présente <span>{{ data.version }}</span> de la charte a été adoptée après
      information et consultation du comité de pilotage de la conformité.<br />
      Elle est applicable à compter du
      <span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{data.dateRouge}}</span
      >.
    </p>
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <br />
    <h5
      style="
        font-family: 'Calibri', sans-serif;
        text-decoration: underline;
        text-align: center;
      "
    >
      ANNEXE
    </h5>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
      "
    >
      DISPOSITIONS LEGALES APPLICABLES<br />
      Directive 95/46/CE du 24 octobre 1995 relative à la protection des
      personnes physiques à l’égard du traitement des données à caractère
      personnel et à la libre circulation de ces données.<br />
      Loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
      aux libertés ; modifiée par la loi n°2004-801 du 6 août 2004.<br />
      Dispositions Pénales :<br />
      - Code Pénal (partie législative) : art 226-16 à 226-24<br />
      - Code Pénal (partie réglementaire) : art R. 625-10 à R. 625-13<br />
      Loi n°88-19 du 5 janvier 1988 relative à la fraude informatique dite loi
      Godfrain.<br />
      Dispositions pénales : art 323-1 à 323-3 du Code pénal.<br />
      Loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique
      (LCEN)<br />
      Loi n°94-361 du 10 mai 1994 sur la propriété intellectuelle des
      logiciels.<br />
      Disposition pénale : art L.335-2 du Code pénal.<br />
    </p>
  </div>
</template>

<script>
import selectBox from './components/selectBox.vue';
import TextField from './components/textField.vue';
export default {
  components: { selectBox, TextField },
  name: "charteInformatique",
  props:{
    data:{
      type:Object,
      default:()=>{}
    },
    oldReference:{
        type:Object,
        default:()=>{}
    },
  },
  data(){
    return{
      referenceData:{
        menuBlueSelectedItem:'',
        password:'',
        duration:''
      }
    }
  },
  mounted() {
    if(!!this.oldReference && Object.keys(this.oldReference))
      this.referenceData = this.oldReference
  },
  watch:{
    referenceData:{
      handler(value){
        this.$store.dispatch('handleReferenceData',value)
      },
      deep:true
    },
  }
};
</script>

<style></style>
