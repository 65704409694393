<template>
  <div class="chaptre">
    <div
      class="tw-grid tw-grid-cols-12 tw-gap-4"
      @click="$emit('selectChapter', chapter.id)"
    >
      <div class="tw-col-span-8" v-html="`${chapterOrderNumber}. ${formatChapterLabel(chapter.label)}`"></div>
      <div class="tw-col-span-3">
        <v-progress-linear :value="counter" :color="progressBarColor" height="10" rounded></v-progress-linear>
      </div>
      <div class="tw-col-auto">
        <div class="tw-float-right tw-flex tw-items-center tw-space-x-6">
          <svg v-if="canShowChapterIcon" width="8" height="20" viewBox="0 0 8 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.875 0C2.84729 0 1.86166 0.408258 1.13496 1.13496C0.408258 1.86166 0 2.84729 0 3.875C0 6.795 1.207 10.427 1.813 12.074C1.97001 12.4939 2.25213 12.8556 2.62124 13.1101C2.99035 13.3645 3.42867 13.4996 3.877 13.497C4.781 13.497 5.616 12.955 5.94 12.079C6.546 10.439 7.75 6.825 7.75 3.875C7.75 2.84729 7.34174 1.86166 6.61504 1.13496C5.88834 0.408258 4.90271 0 3.875 0ZM1.5 3.875C1.5 3.24511 1.75022 2.64102 2.19562 2.19562C2.64102 1.75022 3.24511 1.5 3.875 1.5C4.50489 1.5 5.10898 1.75022 5.55438 2.19562C5.99978 2.64102 6.25 3.24511 6.25 3.875C6.25 6.53 5.139 9.918 4.533 11.559C4.48158 11.6901 4.39126 11.8024 4.27417 11.8807C4.15708 11.959 4.01883 11.9996 3.878 11.997C3.7366 11.9998 3.59773 11.9591 3.48022 11.8804C3.36271 11.8017 3.27222 11.6888 3.221 11.557C2.614 9.905 1.5 6.499 1.5 3.875ZM3.876 14.999C3.21269 14.999 2.57655 15.2625 2.10753 15.7315C1.6385 16.2006 1.375 16.8367 1.375 17.5C1.375 18.1633 1.6385 18.7994 2.10753 19.2685C2.57655 19.7375 3.21269 20.001 3.876 20.001C4.53931 20.001 5.17545 19.7375 5.64447 19.2685C6.1135 18.7994 6.377 18.1633 6.377 17.5C6.377 16.8367 6.1135 16.2006 5.64447 15.7315C5.17545 15.2625 4.53931 14.999 3.876 14.999ZM2.875 17.5C2.875 17.3685 2.90089 17.2384 2.9512 17.1169C3.0015 16.9955 3.07523 16.8851 3.16819 16.7922C3.26114 16.6992 3.37149 16.6255 3.49293 16.5752C3.61438 16.5249 3.74455 16.499 3.876 16.499C4.00745 16.499 4.13762 16.5249 4.25907 16.5752C4.38051 16.6255 4.49086 16.6992 4.58381 16.7922C4.67676 16.8851 4.7505 16.9955 4.8008 17.1169C4.85111 17.2384 4.877 17.3685 4.877 17.5C4.877 17.7655 4.77154 18.0201 4.58381 18.2078C4.39609 18.3955 4.14148 18.501 3.876 18.501C3.61052 18.501 3.35591 18.3955 3.16819 18.2078C2.98046 18.0201 2.875 17.7655 2.875 17.5Z" fill="#E8973E"/>
          </svg>
          
          <svg class="tw-cursor-pointer" width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666667 0C0.489856 0 0.320286 0.070238 0.195262 0.195262C0.0702379 0.320287 0 0.489856 0 0.666667C0 0.843478 0.0702379 1.01305 0.195262 1.13807C0.320286 1.2631 0.489856 1.33333 0.666667 1.33333H16.6667C16.8435 1.33333 17.013 1.2631 17.1381 1.13807C17.2631 1.01305 17.3333 0.843478 17.3333 0.666667C17.3333 0.489856 17.2631 0.320287 17.1381 0.195262C17.013 0.070238 16.8435 0 16.6667 0H0.666667ZM0 6C0 5.82319 0.0702379 5.65362 0.195262 5.5286C0.320286 5.40357 0.489856 5.33333 0.666667 5.33333H16.6667C16.8435 5.33333 17.013 5.40357 17.1381 5.5286C17.2631 5.65362 17.3333 5.82319 17.3333 6C17.3333 6.17681 17.2631 6.34638 17.1381 6.4714C17.013 6.59643 16.8435 6.66667 16.6667 6.66667H0.666667C0.489856 6.66667 0.320286 6.59643 0.195262 6.4714C0.0702379 6.34638 0 6.17681 0 6ZM0 11.3333C0 11.1565 0.0702379 10.987 0.195262 10.8619C0.320286 10.7369 0.489856 10.6667 0.666667 10.6667H16.6667C16.8435 10.6667 17.013 10.7369 17.1381 10.8619C17.2631 10.987 17.3333 11.1565 17.3333 11.3333C17.3333 11.5101 17.2631 11.6797 17.1381 11.8047C17.013 11.9298 16.8435 12 16.6667 12H0.666667C0.489856 12 0.320286 11.9298 0.195262 11.8047C0.0702379 11.6797 0 11.5101 0 11.3333Z" :fill="referer.theme.lastStepProgressquiz"/>
          </svg>
        </div>
      </div>
    </div>

    <template v-if="canShowQuestions">
      <div v-for="(question, i) in chapter.questions" :key="question.id">
        <div 
          v-if="!excludedQuestions.includes(question.id)"
          class="subChaptre tw-grid tw-grid-cols-12 tw-gap-4 victor-grid"
          style="cursor: default;"
        >
          <div
            :class="{
              'tw-col-span-8': question.questionType === 'radio',
              'tw-col-span-12': question.questionType !== 'radio',
            }"
          >
            <span>{{ chapterOrderNumber }}.{{ i + 1 }}</span>&nbsp;
            <span style="font-size: 14px !important;">{{ question.label }}</span>
          </div>

          <div v-if="question.questionType === 'radio'" class="tw-flex tw-items-center tw-justify-end tw-col-span-2 tw-space-x-6 tw-pr-4">
            <svg 
              class="tw-cursor-pointer" 
              width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg"
              v-if="tips.canDisplayIcons(`${chapterOrderNumber}.${i+1}`, getSelectedAnswerLabel(question.id))"
              @click="showIllumination(`${chapterOrderNumber}.${i+1}`)" 
            >
              <path d="M16 8.62C16 11.2 14.73 13.185 12.798 14.492C12.348 14.796 12.123 14.948 12.012 15.122C11.902 15.294 11.863 15.522 11.788 15.976L11.728 16.329C11.596 17.127 11.529 17.526 11.249 17.763C10.969 18 10.565 18 9.756 18H7.144C6.335 18 5.931 18 5.651 17.763C5.371 17.526 5.305 17.127 5.171 16.329L5.113 15.976C5.037 15.523 5 15.296 4.89 15.124C4.78 14.952 4.554 14.798 4.103 14.49C2.192 13.183 1 11.199 1 8.62C1 4.413 4.358 1 8.5 1C9.00387 0.999471 9.50648 1.0504 10 1.152" stroke="#6C5DD3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.5 18V19C10.5 19.943 10.5 20.414 10.207 20.707C9.914 21 9.443 21 8.5 21C7.557 21 7.086 21 6.793 20.707C6.5 20.414 6.5 19.943 6.5 19V18M13.5 1L13.758 1.697C14.096 2.611 14.265 3.068 14.598 3.401C14.932 3.735 15.389 3.904 16.303 4.242L17 4.5L16.303 4.758C15.389 5.096 14.932 5.265 14.599 5.598C14.265 5.932 14.096 6.389 13.758 7.303L13.5 8L13.242 7.303C12.904 6.389 12.735 5.932 12.402 5.599C12.068 5.265 11.611 5.096 10.697 4.758L10 4.5L10.697 4.242C11.611 3.904 12.068 3.735 12.401 3.402C12.735 3.068 12.904 2.611 13.242 1.697L13.5 1Z" stroke="#6C5DD3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg 
              class="tw-cursor-pointer" 
              width="8" height="20" viewBox="0 0 8 20" fill="none" xmlns="http://www.w3.org/2000/svg"
              v-if="tips.canDisplayIcons(`${chapterOrderNumber}.${i+1}`, getSelectedAnswerLabel(question.id))" 
              @click="showRecommendation(`${chapterOrderNumber}.${i+1}`)"
            >
              <path d="M3.875 0C2.84729 0 1.86166 0.408258 1.13496 1.13496C0.408258 1.86166 0 2.84729 0 3.875C0 6.795 1.207 10.427 1.813 12.074C1.97001 12.4939 2.25213 12.8556 2.62124 13.1101C2.99035 13.3645 3.42867 13.4996 3.877 13.497C4.781 13.497 5.616 12.955 5.94 12.079C6.546 10.439 7.75 6.825 7.75 3.875C7.75 2.84729 7.34174 1.86166 6.61504 1.13496C5.88834 0.408258 4.90271 0 3.875 0ZM1.5 3.875C1.5 3.24511 1.75022 2.64102 2.19562 2.19562C2.64102 1.75022 3.24511 1.5 3.875 1.5C4.50489 1.5 5.10898 1.75022 5.55438 2.19562C5.99978 2.64102 6.25 3.24511 6.25 3.875C6.25 6.53 5.139 9.918 4.533 11.559C4.48158 11.6901 4.39126 11.8024 4.27417 11.8807C4.15708 11.959 4.01883 11.9996 3.878 11.997C3.7366 11.9998 3.59773 11.9591 3.48022 11.8804C3.36271 11.8017 3.27222 11.6888 3.221 11.557C2.614 9.905 1.5 6.499 1.5 3.875ZM3.876 14.999C3.21269 14.999 2.57655 15.2625 2.10753 15.7315C1.6385 16.2006 1.375 16.8367 1.375 17.5C1.375 18.1633 1.6385 18.7994 2.10753 19.2685C2.57655 19.7375 3.21269 20.001 3.876 20.001C4.53931 20.001 5.17545 19.7375 5.64447 19.2685C6.1135 18.7994 6.377 18.1633 6.377 17.5C6.377 16.8367 6.1135 16.2006 5.64447 15.7315C5.17545 15.2625 4.53931 14.999 3.876 14.999ZM2.875 17.5C2.875 17.3685 2.90089 17.2384 2.9512 17.1169C3.0015 16.9955 3.07523 16.8851 3.16819 16.7922C3.26114 16.6992 3.37149 16.6255 3.49293 16.5752C3.61438 16.5249 3.74455 16.499 3.876 16.499C4.00745 16.499 4.13762 16.5249 4.25907 16.5752C4.38051 16.6255 4.49086 16.6992 4.58381 16.7922C4.67676 16.8851 4.7505 16.9955 4.8008 17.1169C4.85111 17.2384 4.877 17.3685 4.877 17.5C4.877 17.7655 4.77154 18.0201 4.58381 18.2078C4.39609 18.3955 4.14148 18.501 3.876 18.501C3.61052 18.501 3.35591 18.3955 3.16819 18.2078C2.98046 18.0201 2.875 17.7655 2.875 17.5Z" fill="#E8973E"/>
            </svg>
          </div>

          <div class="tw-col-span-2" v-if="question.questionType === 'radio'">
            <RadioButtonComponent
              hide-details
              :answers="question.responses"
              :selectedAnswerId="getSelectedAnswerId(question.id)"
              @answered="updateAnswer($event, question.id)"
            />
          </div>
          <div class="tw-col-span-12" v-if="question.questionType === 'select_one'">
            <SelectQuestionnaireComponent
              hide-details
              :answers="question.responses"
              :selectedAnswerId="getSelectedAnswerId(question.id)"
              @answered="updateAnswer($event, question.id)"
            />
          </div>
          <div class="tw-col-span-12" v-if="question.questionType === 'select_many'">
            <CheckButtonComponent
              hide-details
              :answers="question.responses"
              :selectedAnswers="getSelectedAnswers(question.id)"
              :isLastAnswerIsNullifiable="questionsWithNullifiableAnswers.includes(question.id)"
              @answered="updateManyAnswers($event, question.id)"
              :class="{'first-label-bold': i === 3}"
            />
          </div>
        </div>
      </div>
    </template>

    <v-dialog 
      content-class="tip-dialog"
      v-if="canShowIllumination" 
      v-model="canShowIllumination" 
      max-width="1175px" persistent
    >
      <v-card elevation="0" :style="{ border: `2.5px solid ${referer.theme.ctaBgColor}`, borderRadius: '16px', paddingBottom: '2rem' }">
        <v-icon style="margin-top: .75rem; margin-left: .75rem;" @click="canShowIllumination = false" :color="referer.theme.ctaBgColor">mdi-close</v-icon>
        <v-card-title class="headline tw-justify-center" style="padding-top: 0 !important;">
          ÉCLAIRAGE
        </v-card-title>
        <v-card-text style="margin: 4rem 0; padding: 0 4rem;">
          {{ tips.getIllumnation(selectedQuestionIdForTips) }}
        </v-card-text>
        <v-card-actions class="tw-justify-center">
          <v-btn style="border-radius: 10px; padding: 12px 32px;" color="primary" elevation="0" @click="canShowIllumination = false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog 
      content-class="tip-dialog"
      v-if="canShowRecommendation" 
      v-model="canShowRecommendation" 
      max-width="1175px" persistent
    >
      <v-card elevation="0" :style="{ border: `2.5px solid ${referer.theme.ctaBgColor}`, borderRadius: '16px', paddingBottom: '2rem' }">
        <v-icon 
          style="margin-top: .75rem; margin-left: .75rem;" 
          :color="referer.theme.ctaBgColor"
          @click="(canShowRecommendation = false, canShowRecommendationDetail = false)"
        >mdi-close</v-icon>
        <v-card-title class="headline tw-justify-center" style="padding-top: 0 !important;">
          RECOMMANDATION
        </v-card-title>
        <v-card-text style="margin: 4rem 0; padding: 0 4rem; text-align: center; font-weight: bold;">
          <p>{{ tips.getRecommendationTitle(selectedQuestionIdForTips) }}</p>

          <div v-if="canShowRecommendationDetail" class="tw-mt-8" style="font-weight: 400;">
            <p>{{ tips.getRecommendationDetails(selectedQuestionIdForTips) }}</p>
          </div>

          <div 
            class="tw-mt-8 tw-cursor-pointer" 
            v-if="!canShowRecommendationDetail"
            @click="canShowRecommendationDetail = true"
          >
            <span class="tw-underline" style="font-weight: 400;">Voir plus</span>
            <v-icon :color="referer.theme.ctaBgColor">mdi-chevron-down</v-icon>
          </div>
          <div 
            class="tw-mt-8 tw-cursor-pointer" 
            v-if="canShowRecommendationDetail"
            @click="canShowRecommendationDetail = false"
          >
            <span class="tw-underline" style="font-weight: 400;">Voir moins</span>
            <v-icon :color="referer.theme.ctaBgColor">mdi-chevron-up</v-icon>
          </div>
        </v-card-text>
        <v-card-actions class="tw-justify-center">
          <v-btn 
            style="border-radius: 10px; padding: 12px 32px;" 
            color="primary" elevation="0" 
            @click="(canShowRecommendation = false, canShowRecommendationDetail = false)"
          >Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RadioButtonComponent from "@/components/common/RadioButtonQuestionnaireComponent";
import SelectQuestionnaireComponent from "@/components/common/SelectQuestionnaireComponent";
import CheckButtonComponent from "@/components/common/CheckButtonQuestionnaireComponent";
import * as _tips from "@/features/company/components/Mocks/quiz_tips.js"

export default {
  name: "RGPDChapterComponent",
  components: {
    RadioButtonComponent,
    SelectQuestionnaireComponent,
    CheckButtonComponent,
  },
  props: {
    chapter: {
      type: Object,
      default: null,
    },
    savedChapter: {
      type: Object,
      default: null,
    },
    chapterOrderNumber: {
      type: Number,
      required: true,
    },
    canShowQuestions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      questions: [],
      counter: 0,
      excludedQuestions: [9],
      questionsWithNullifiableAnswers: [49, 50, 51],
      canShowChapterIcon: false,
      tips: _tips,
      selectedQuestionIdForTips: null,
      canShowIllumination: false,
      canShowRecommendation: false,
      canShowRecommendationDetail: false,
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    progressBarColor() {
      if (this.counter < 50) {
        return this.referer.theme.firstStepProgressquiz;
      } else if (this.counter >= 50 && this.counter < 100) {
        return this.referer.theme.secondStepProgressquiz;
      } else {
        return this.referer.theme.lastStepProgressquiz;
      }
    },
  },
  mounted() {
    this.questions = this.chapter.questions.map((q) => {
      return {
        questionId: q.id,
        responses: q.responses.map((r) => {
          return {
            id: r.id,
            label: r.label,
            responseId: null,
          };
        }),
      };
    });
    
    this.fillWithSavedChapter(this.savedChapter);
  },
  watch: {
    savedChapter: {
      handler(chapter) {
        this.fillWithSavedChapter(chapter, true);
      },
      deep: true,
    },
    questions: {
      handler() {
        this.canShowChapterIcon = this.checkCanShowChapterIcon();
      },
      deep: true,
    },
  },
  methods: {
    formatChapterLabel(label) {
      const [ primary, secondary ] = label.split(" – ");

      return secondary ? `${primary} <span class="tw-font-normal">– ${secondary}</span>` : primary;
    },
    fillWithSavedChapter(savedChapter, withNullifing = false) {
      if (savedChapter) {
        this.questions = this.questions.map((q) => {
          return {
            ...q,
            responses: savedChapter.questions
              .find((sq) => sq.questionId === q.questionId)
              .responses.map((r) => {
                return {
                  ...r,
                  responseId: r.responseId,
                };
              }),
          }
        });
      }

      if (withNullifing) this.nullifyExcludedQuestionsAnswers();
      this.calculateProgress();
    },
    nullifyExcludedQuestionsAnswers() {
      this.questions = this.questions.map((q) => {
        if (this.excludedQuestions.includes(q.questionId)) {
          return {
            ...q,
            responses: q.responses.map((r) => {
              return {
                ...r,
                responseId: null
              };
            })
          };
        }
        return q;
      });
    },
    getSelectedAnswerId(questionId) {
      return this.questions.find(q => q.questionId == questionId)?.responses.find((r) => r.responseId)?.responseId
    },
    getSelectedAnswerLabel(questionId) {
      return this.questions.find(q => q.questionId == questionId)?.responses.find((r) => r.responseId)?.label
    },
    getSelectedAnswers(questionId) {
      return this.questions.find(q => q.questionId == questionId)?.responses.filter((r) => r.responseId);
    },
    updateAnswer(value, questionId) {
      this.questions = this.questions.map((q) => {
        return {
          ...q,
          responses: q.questionId === questionId
            ? q.responses.map((r) => {
              return {
                ...r,
                responseId: r.id === value ? value : null
              };
            })
            : q.responses,
        };
      });

      this.nullifyExcludedQuestionsAnswers();
      this.calculateProgress();
    },
    updateManyAnswers(values, questionId) {
      this.questions = this.questions.map((q) => {
        if (
          q.questionId === questionId &&
          this.questionsWithNullifiableAnswers.includes(questionId)
        ) {
          const isLastAnswerIsSelected = !!q.responses[q.responses.length - 1].responseId;
          const isFirstAnswerIsSelected = !!q.responses[0].responseId;
          
          return {
            ...q,
            responses: q.responses.map((r) => {
              if (questionId === 51) {
                return {
                  ...r,
                  responseId: !isFirstAnswerIsSelected && !!values[0]
                    ? r.id === values[0] ? r.id : null
                    : values.includes(r.id) && r.id !== values[0] ? r.id : null
                };
              }

              return {
                ...r,
                responseId: !isLastAnswerIsSelected && !!values[values.length - 1]
                  ? r.id === values[values.length - 1] ? r.id : null
                  : values.includes(r.id) && r.id !== values[values.length - 1] ? r.id : null
              };
            })
          };
        }
        
        return {
          ...q,
          responses: q.questionId === questionId
            ? q.responses.map((r) => {
              return {
                ...r,
                responseId: values.includes(r.id) ? r.id : null
              };
            })
            : q.responses,
        };
      });

      this.nullifyExcludedQuestionsAnswers();
      this.calculateProgress();
    },
    calculateProgress() {
      setTimeout(() => {
        const filteredQuestions = this.questions.filter((q) => {
          return !this.excludedQuestions.includes(q.questionId);
        });

        const answeredQuestionsCount = filteredQuestions.filter((q) => {
          return q.responses.find((r) => r.responseId);
        }).length;

        this.counter = (answeredQuestionsCount / filteredQuestions.length) * 100;
        
        this.$emit('input-changed', this.chapter.id, this.questions);
      }, 400);
    },
    checkCanShowChapterIcon() {
      if (!this.questions.length) return false;

      const ids = this.chapter.questions
        .map((q, index) => [this.chapter.id, index+1].join('.'));

      const answers = this.questions.map((q, index) => {
        const questionId = [this.chapter.id, index+1].join('.');
        const answer = q.responses.filter(r => r.responseId !== null).map(r => r.label)[0] || 'ignore';
        
        return { [questionId]: answer };
      }).reduce((acc, curr) => ({ ...acc, ...curr }), {});

      return _tips.canDisplayRecommendationIcon(ids, answers);
    },
    showIllumination(questionId) {
      this.selectedQuestionIdForTips = questionId;
      this.canShowIllumination = true;
    },
    showRecommendation(questionId) {
      this.selectedQuestionIdForTips = questionId;
      this.canShowRecommendation = true;
    },
  }
}
</script>

<style>
.first-label-bold .v-input--checkbox:first-child label {
  font-weight: bold !important;
}
.tip-dialog { 
  background-color: transparent !important; 
  box-shadow: none !important;
}
</style>