<template>
  <div style="width: 90%; margin: 0 auto; letter-spacing: 0.5px">
    <p
      style="
        text-align: center;
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
        font-weight: bold;
      "
    >
      Mention contractuelle sous-traitants
    </p>
    <br /><br /><br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      Dans le cadre de la politique de sécurisation des ses sites et de ses
      collaborateurs, notre entreprise a été amené à vous demander un certain
      nombre d’information à caractère personnel.<br /><br />
      Nous vous informons que ces informations recueillies sur cette fiche de
      consigne sont enregistrées dans un fichier informatisé par
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
        class="social_client"
      ></span>
      pour assurer l’exécution de ses obligations contractuelles.<br /><br />
      Elles seront conservées tout au long de la durée du contrat, période
      durant laquelle elles pourront être régulièrement mises à jour à votre
      demande. <br /><br />
      Hors obligations légales spécifiques, elles seront détruites sous un délai
      de 3 mois après que nous ayons mis un terme au contrat ou après votre
      départ de l’entreprise.<br /><br />
      Ces données sont destinées aux collaborateurs de
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
        class="social_client"
      ></span
      >.<br /><br />
      Conformément à la loi « informatique et libertés », vous pouvez exercer
      votre droit d'accès aux données vous concernant et les faire rectifier en
      contactant :
      <a
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
        :href="`mailto:${data.email}`"
      >{{ data.email }}</a
      ><br /><br />
      Nous vous informons de l’existence de la liste d'opposition au démarchage
      téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici :
      <a
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
        target="_blank"
        href="https://www.bloctel.gouv.fr/"
        >https://www.bloctel.gouv.fr/</a
      ><br /><br />
    </p>
  </div>
</template>

<script>
export default {
  name: "MentionContractuelleSoustraitants",
  props:{
    data:{
      type:Object,
      default:()=>{}
    },
    oldReference:{
      type:Object,
      default:()=>{}
    }
  },
  mounted() {
    if(!!this.oldReference && Object.keys(this.oldReference))
      this.referenceData = this.oldReference
    this.$store.dispatch('handleReferenceData',{})
  },
};
</script>

<style></style>
