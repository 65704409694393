<template>
  <div id="collaborateurs-table" class="tw-pt-8 tw-px-1 tw-mt-8">
    <div class="tw-flex tw-flex-col tw-align-start tw-justify-between">
      <div class="tw-flex tw-flex-col">
        <EntrepriseHeaderBlockTextComponent title="Docuthèque" />
        <p class="tw-text-sm tw-mt-4">
          Importez l’ensemble de la documentation propore à votre entreprise.
        </p>
      </div>

      <v-btn :style="{
        backgroundColor: `${theme.ctaBgColor} !important`,
        color: `${theme.ctaTextColor} !important`
      }" style="width: fit-content; padding: .5rem 2rem;" elevation="0" rounded
        class="tw-mt-4 tw-flex tw-items-center tw-justify-center" @click="showAddDialog">
        <svg class="tw-mr-3" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.25 12.175V2.9L4.25 5.9L3.175 4.825L8 0L12.825 4.825L11.75 5.9L8.75 2.9V12.175H7.25ZM1.5 16C1.1 16 0.75 15.85 0.45 15.55C0.15 15.25 0 14.9 0 14.5V10.925H1.5V14.5H14.5V10.925H16V14.5C16 14.9 15.85 15.25 15.55 15.55C15.25 15.85 14.9 16 14.5 16H1.5Z"
            fill="white" />
        </svg>
        Ajouter
      </v-btn>

      <div class="tw-w-full tw-overflow-auto filter tw-border-2 tw-p-6 tw-rounded-lg border-primary tw-mt-6">
        <search-text-field-component class="tw-mb-3" v-model="keyWord" label="Rechercher" append-icon="mdi-magnify"
          @change="search" />

        <content-placeholders v-if="loading_table">
          <content-placeholders-text :lines="10" />
        </content-placeholders>

        <v-simple-table v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="tw-w-2/5">Nom</th>
                <th class="!tw-text-center">Date</th>
                <th class="!tw-text-center">Visualisation</th>
                <th class="!tw-text-center">Impression</th>
                <th class="!tw-text-center">Supprimer</th>
              </tr>
            </thead>
            <tbody v-if="documents.length > 0">
              <tr v-for="document in documents" :key="document.id">
                <td :title="document.name">{{ document.name.truncate(80) }}</td>
                <td class="!tw-text-center" :title="document.created_at">{{ document.created_at }}</td>
                <td title="visualisation" class="!tw-text-center">
                  <svg class="tw-cursor-pointer" style="margin: 0 auto;"
                    @click="$router.push({ name: 'Gouvernance.Docutheque.Preview', params: { id: document.id } })"
                    width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.691 8.44877C15.691 9.40908 15.3095 10.3301 14.6305 11.0091C13.9514 11.6882 13.0304 12.0696 12.0701 12.0696C11.1098 12.0696 10.1888 11.6882 9.50975 11.0091C8.8307 10.3301 8.44922 9.40908 8.44922 8.44877C8.44922 7.48845 8.8307 6.56746 9.50975 5.88841C10.1888 5.20937 11.1098 4.82788 12.0701 4.82788C13.0304 4.82788 13.9514 5.20937 14.6305 5.88841C15.3095 6.56746 15.691 7.48845 15.691 8.44877Z"
                      :fill="theme.ctaBgColor" />
                    <path
                      d="M24.0106 7.90922C21.406 2.69877 16.7809 0 12.069 0C7.3582 0 2.73192 2.69877 0.127293 7.90922C0.0435802 8.07675 0 8.26145 0 8.44873C0 8.63601 0.0435802 8.82072 0.127293 8.98824C2.73192 14.1987 7.35699 16.8975 12.069 16.8975C16.7797 16.8975 21.406 14.1987 24.0106 8.98824C24.0944 8.82072 24.1379 8.63601 24.1379 8.44873C24.1379 8.26145 24.0944 8.07675 24.0106 7.90922ZM12.069 14.4835C8.4855 14.4835 4.82479 12.5283 2.57018 8.44873C4.82479 4.3692 8.48429 2.41392 12.069 2.41392C15.6524 2.41392 19.3132 4.3692 21.5678 8.44873C19.3132 12.5283 15.6524 14.4835 12.069 14.4835Z"
                      :fill="theme.ctaBgColor" />
                  </svg>
                </td>
                <td title="impression">
                  <svg 
                    class="tw-cursor-pointer" @click="downloadDocument(document)"
                    style="display: block; width: fit-content; margin: 0 auto;" width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.9712 4.22114H16.5682V2.15793C16.5906 1.63422 16.4044 1.12298 16.0506 0.736243C15.6967 0.349508 15.204 0.11882 14.6803 0.0947266H6.07677C5.55313 0.11882 5.06039 0.349508 4.70653 0.736243C4.35266 1.12298 4.16654 1.63422 4.18893 2.15793V4.22114H2.78595C2.05997 4.22659 1.36574 4.51952 0.855287 5.03577C0.344835 5.55203 0.0597699 6.24953 0.0625197 6.97552V13.846C0.0597699 14.572 0.344835 15.2695 0.855287 15.7857C1.36574 16.302 2.05997 16.5949 2.78595 16.6004H3.67313C3.67313 17.1476 3.8905 17.6724 4.27743 18.0593C4.66436 18.4462 5.18914 18.6636 5.73634 18.6636H15.0208C15.568 18.6636 16.0928 18.4462 16.4797 18.0593C16.8666 17.6724 17.084 17.1476 17.084 16.6004H17.9712C18.6971 16.5949 19.3914 16.302 19.9018 15.7857C20.4123 15.2695 20.6973 14.572 20.6946 13.846V6.97552C20.6973 6.24953 20.4123 5.55203 19.9018 5.03577C19.3914 4.51952 18.6971 4.22659 17.9712 4.22114ZM6.25214 2.15793H14.505V4.22114H6.25214V2.15793ZM5.73634 16.6004V12.474H15.0208V16.6004H5.73634ZM18.6314 13.846C18.6342 14.0248 18.5665 14.1976 18.4429 14.3269C18.3194 14.4563 18.1499 14.5318 17.9712 14.5372H17.084V12.474C17.084 11.9268 16.8666 11.402 16.4797 11.0151C16.0928 10.6281 15.568 10.4108 15.0208 10.4108H5.73634C5.18914 10.4108 4.66436 10.6281 4.27743 11.0151C3.8905 11.402 3.67313 11.9268 3.67313 12.474V14.5372H2.78595C2.60718 14.5318 2.43771 14.4563 2.31417 14.3269C2.19062 14.1976 2.12294 14.0248 2.12573 13.846V6.97552C2.12294 6.79669 2.19062 6.62394 2.31417 6.49461C2.43771 6.36527 2.60718 6.28975 2.78595 6.28435H17.9712C18.1499 6.28975 18.3194 6.36527 18.4429 6.49461C18.5665 6.62394 18.6342 6.79669 18.6314 6.97552V13.846Z"
                      :fill="theme.ctaBgColor" />
                  </svg>
                </td>
                <td title="supprimer">
                  <svg class="tw-cursor-pointer" style="margin: 0 auto;" @click="deleteDocument(document.id)" width="18"
                    height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 1L1 17M1 1L17 17" :stroke="theme.ctaBgColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5" class="text-center">
                  Pas de données disponibles
                </td>
              </tr>
            </tbody>
            <tfoot class="text-center" v-if="length > 1">
              <tr>
                <td colspan="5">
                  <v-pagination :value="page" :length="length" @input="pagination"></v-pagination>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </div>
    </div>

    <ImportDocumentationPopup v-if="canShowImportDocumentationPopup" :openPopup="canShowImportDocumentationPopup"
      @close="canShowImportDocumentationPopup = false" @closeAndFetch="closeAndFetch" />

    <v-dialog v-if="showDeleteDialog" v-model="showDeleteDialog" max-width="500px">
      <v-card elevation="0">
        <v-card-title class="headline">Confirmer la suppression</v-card-title>
        <v-card-text>Êtes-vous sûr de vouloir supprimer ce document ? Cette action est irréversible.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :style="{ color: theme.ctaBgColor }" text @click="showDeleteDialog = false">Annuler</v-btn>
          <v-btn :style="{ color: '#FF6347' }" text @click="confirmDelete">Supprimer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SearchTextFieldComponent from '@/components/common/SearchTextFieldComponent.vue';
import ImportDocumentationPopup from "@/features/company/pages/MonEntreprise/Collaborateurs/Gouvernance/components/ImportDocumentationPopup.vue";
import { getDocumentations, deleteDocumentationFile } from "@/features/company/services/appApi";
import ApiService from "@/services/api.service";

export default {
  name: 'GouvernanceDocuthequeListPage',
  components: {
    EntrepriseHeaderBlockTextComponent,
    SearchTextFieldComponent,
    ImportDocumentationPopup,
  },
  data() {
    return {
      keyWord: '',
      loading_table: true,
      documents: [],
      page: 1,
      length: 1,
      canShowImportDocumentationPopup: false,
      selectedDocumentIdToDelete: null,
      showDeleteDialog: false,
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  mounted() {
    this.getDocumentationsList();
  },
  methods: {
    getDocumentationsList() {
      getDocumentations(this.keyWord, this.page)
        .then(({ data: { items, meta } }) => {
          this.documents = items.map(item => ({
            id: item.id,
            name: item.name,
            created_at: new Date(item.createdAt).toLocaleDateString({ locale: 'fr-FR' }),
            path: item.path,
          }));
          this.length = meta.totalPages;
        })
        .catch(error => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur s'est produite lors de la récupération des documents",
            type: "error",
          });
        })
        .finally(() => this.loading_table = false);
    },
    showAddDialog() {
      this.canShowImportDocumentationPopup = true;
    },
    search() {
      this.page = 1;
      this.getDocumentationsList();
    },
    pagination(page) {
      if (this.page === page) return;
      this.page = page;
      this.getDocumentationsList();
    },
    closeAndFetch() {
      this.canShowImportDocumentationPopup = false;
      this.getDocumentationsList();
    },
    deleteDocument(id) {
      this.selectedDocumentIdToDelete = id;
      this.showDeleteDialog = true;
    },
    confirmDelete() {
      this.showDeleteDialog = false;
      deleteDocumentationFile(this.selectedDocumentIdToDelete)
        .then(() => {
          this.$snackbar.showAlertMessage({
            message: "Le document a été supprimé avec succès",
            type: "success",
          });
          this.getDocumentationsList();
        })
        .catch(error => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur s'est produite lors de la suppression du document",
            type: "error",
          });
        });
    },
    downloadDocument(document) {
      this.$snackbar.showAlertMessage({
        message: "Téléchargement en cours...",
        type: "info",
      });
      ApiService.post("media/download", {
        collection: "documentations",
        entityId: document.id,
        entityType: "Documentation",
        entityField: "path",
      })
      .then(({ data }) => {
        const link = window.document.createElement('a');
        link.href = data;
        link.setAttribute('download', document.path);
        link.click();
      })
      .catch(error => {
        console.log(error);
        this.$snackbar.showAlertMessage({
          message: "Une erreur s'est produite lors de la récupération de la documentation",
          type: "error",
        });
      })
    },
  },
}
</script>