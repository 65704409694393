import axios from "axios";
import { serverConfig } from "../../../config/serverConfig";
import ApiService from "@/services/api.service";

export const firstStepSignUpApi = (data) => {
  return axios.post(
    `${serverConfig.appUrl}/auth/signup/first-step?hostname=${location.host}`,
    {
      firstName: data.firstName,
      gender: data.gender,
      lastName: data.lastName,
      email: data.email,
      socialReason: data.socialReason,
      sirenOrRna: data.sirenOrRna,
      legalStatus: data.legalStatus,
      phone: data.phone,
      password: data.password,
    }
  );
};

export const checkIfUserCanBypassMaintenance = (referrerId, key) => {
	return axios.post(`${serverConfig.appUrl}/affiliate/check-bypass-key/${referrerId}`, { key });
}

export const getUserDataByToken = (token) => {
  return axios.get(`${serverConfig.appUrl}/auth/signup/second-step/${token}`);
};

export const lastStepSignUpApi = (token) => {
  return axios.post(
    `${serverConfig.appUrl}/auth/signup/last-step/${token}?hostname=${location.host}`
  );
};

export const postLastStepRegistrationManyCollabo = (token, data) => {
  return axios.post(
    `${serverConfig.appUrl}/auth/signup/third-step-add-collaborateurs/${token}`, {
      collaborators : data
    }
  );
};

export const login = (data) => {
  return axios.post(`${serverConfig.appUrl}/auth`, data);
};

export const twoFactorValidation = (data) => {
  return axios.post(`${serverConfig.appUrl}/auth/signin/verification`, data);
};

export const getProfile = () => {
  return axios.get(`${serverConfig.appUrl}/profile`);
};

export const getAllOffers = () => {
  return axios.get(`${serverConfig.appUrl}/plan/all`);
};

export const choseOfferPlan = (id) => {
  return axios.post(`${serverConfig.appUrl}/auth/signin/chose-offer/${id}`);
};
export const choseOfferPlanIron = (id, data) => {
  return axios.post(
    `${serverConfig.appUrl}/auth/signin/chose-offer-iron/${id}`,
    data
  );
};

export const subscriptionUserSepa = (data) => {
  return axios.post(`${serverConfig.appUrl}/auth/signin/subscription-sepa`, data);
};


export const sendResetPasswordEmail = (data) => {
  return axios.post(
    `${serverConfig.appUrl}/reset-password?hostname=${location.host}`,
    data
  );
};

export const resetPassword = (token, data) => {
  return axios.post(
    `${serverConfig.appUrl}/reset-password/change/${token}?hostname=${location.host}`,
    data
  );
};

export const storeCollaborator = (id, data) => {
  return axios.post(
    `${serverConfig.appUrl}/collaborator/subcontractor/invite-one/${id}`,
    data
  );
};

export const getNotificationsUser = () => {
  return ApiService.get(`notification`);
};

export const getDataFromToken = (token) => {
  return axios.get(`${serverConfig.appUrl}/auth/signup/second-step/${token}`);
};

export const secondStepSignUpApi = (token, data) => {
  return axios.post(`${serverConfig.appUrl}/auth/signup/second-step/${token}`, {
    tradeName: data.tradeName,
    corporateOfficer: data.corporateOfficer,
    address: data.address,
    zipCode: data.zipCode,
    city: data.city,
    socialReason: data.socialReason,
    sirenOrRna: data.sirenOrRna,
    legalStatus: data.legalStatus,
    nbrEmployees: data.nbrEmployees,
    service: data.service,
    role: data.role,
    password: data.password,
    apeCode: data.apeCode,
  });
};
export const InvitationSubcontractorSecondStepSignUpApi = (token, data) => {
  return axios.post(
    `${serverConfig.appUrl}/subcontractor/signup/second-step/${token}`,
    {
      tradeName: data.tradeName,
      corporateOfficer: data.corporateOfficer,
      address: data.address,
      zipCode: data.zipCode,
      city: data.city,
      socialReason: data.socialReason,
      sirenOrRna: data.sirenOrRna,
      legalStatus: data.legalStatus,
      nbrEmployees: data.nbrEmployees,
      service: data.service,
      role: data.role,
      password: data.password,
      phone: data.phone,
      apeCode: data.apeCode,
    }
  );
};

export const InvitationExpertCollaboratorSecondStepSignUpApi = (
  token,
  data
) => {
  return axios.post(
    `${serverConfig.appUrl}/expert-client/collaborator/signup/second-step/${token}`,
    {
      service: data.service,
      role: data.role,
      password: data.password,
      phone: data.phone,
    }
  );
};

export function resend2FaVerificationCode(secret) {
  return axios.get(
    `${serverConfig.appUrl}/auth/resend-2fa-verification-code/${secret}`
  );
}

export function preInscriptionLogin(data) {
  return axios.post(
    `${serverConfig.appUrl}/commercial/pre-register/signin`,
    data
  );
}

export const preInscriptionRegistrationFirstStep = (token, data) => {
  return axios.post(
    `${serverConfig.appUrl}/commercial/pre-register/first-step/${token}`,
    data
  );
};

export const preInscriptionCheckToken = (token) => {
  return axios.get(
    `${serverConfig.appUrl}/commercial/pre-register/get-commercial/${token}`
  );
};

export const getPlansByParams = (data) => {
  return axios.get(
    `${serverConfig.appUrl}/plan/by-engagement-and-regulation/${data.engagement}/${data.regulation}`
  );
};

export const postPreRegistrationExpert = (data) => {
  return axios.post(
    `${serverConfig.appUrl}/auth/signup/pre-registration`,
    data
  );
};
export const repostPreRegistrationExpert = (token) => {
  return axios.get(`${serverConfig.appUrl}/auth/signup/pre-registration-resend/${token}`);
};

export const postFirstStepRegistrationExpert = (data, token) => {
  return axios.post(
    `${serverConfig.appUrl}/auth/viqtor-expert/signup/first-step/${token}`,
    data
  );
};

export const postSecondStepRegistrationExpert = (data, token) => {
  return axios.post(
    `${serverConfig.appUrl}/auth/viqtor-expert/signup/second-step/${token}`,
    data
  );
};

export const postLastStepRegistrationExpert = (token) => {
  return axios.post(
    `${serverConfig.appUrl}/auth/viqtor-expert/signup/third-step/${token}`
  );
};

// Assist Sub contractor
export const AssistSendInvitationSubcontractorApi = (token, id) => {
  return axios.post(
    `${serverConfig.appUrl}/subcontractor/assist/send-invitation/${token}/${id}`
  );
};
export const AssistSendInvitationToInterlocuteurSubcontractorApi = (
  token,
  id,
  data
) => {
  return axios.post(
    `${serverConfig.appUrl}/subcontractor/assist/send-invitation-to-interlocuteur/${token}/${id}`,
    data
  );
};
export const assistQuizRegister = (token, data) => {
  return axios.post(
    `${serverConfig.appUrl}/subcontractor/assist/quiz-register/${token}`,
    data
  );
};
export const assistQuizCalculate = (token, data) => {
  return axios.post(
    `${serverConfig.appUrl}/subcontractor/assist/quiz-calculate/${token}`,
    data
  );
};
export const assistQuizSave = (token, data) => {
  return axios.post(
    `${serverConfig.appUrl}/subcontractor/assist/quiz-save/${token}`,
    data
  );
}

export const CollaboratorFirstStepSignUpApi = (token, data) => {
  return axios.post(
    `${serverConfig.appUrl}/auth/collaborator/signup/first-step/${token}`,data
  );
};
export const GetCollaboratorFirstStepSignUpApi = (token) => {
  return axios.get(
    `${serverConfig.appUrl}/auth/collaborator/signup/get-value/${token}`
  );
};

export const CollaboratorLastStepSignUpApi = (token) => {
  return axios.post(
    `${serverConfig.appUrl}/auth/collaborator/signup/last-step/${token}`
  );
};

export const createClientSecret = (data) => {
  return axios.post(`${serverConfig.appUrl}/auth/signin/create-client-secret`, data);
};

export const createClientSecretSepa = (data) => {
  return axios.post(`${serverConfig.appUrl}/auth/signin/create-client-secret-sepa`, data);
};

export const sendRightsExerciceRequest = (data) => {
  return axios.post(
    `${serverConfig.appUrl}/rights-exercice/send-request`,
    data
  );
}