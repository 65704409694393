<template>
  <div style="width: 90%; margin: 0 auto; letter-spacing: 0.5px">
    <p
      style="
        text-align: center;
        font-family: 'Arial', sans-serif;
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
      "
    >
      Engagement de confidentialité
    </p>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 14px;
        line-height: 20px;
      "
    >
      Je soussigné <span v-html="'{{prenom}}'"></span> <span v-html="'{{nom}}'"></span>, exerçant les fonctions de
      <span v-html="'{{fonction}}'"></span> au sein de
      <span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >, étant à ce titre amené/e à accéder à des données à caractère personnel,
      déclare reconnaître la confidentialité desdites données.<br /><br />
      Je m’engage par conséquent, conformément aux articles 34 et 35 de la loi
      du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux
      libertés ainsi qu’aux articles 32 à 35 du règlement général sur la
      protection des données du 27 avril 2016, à prendre toutes précautions
      conformes aux usages et à l’état de l’art dans le cadre de mes
      attributions afin de protéger la confidentialité des informations
      auxquelles j’ai accès, et en particulier d’empêcher qu’elles ne soient
      communiquées à des personnes non expressément autorisées à recevoir ces
      informations.<br /><br />
      Je m’engage en particulier à :
    </p>
    <ul style="list-style: '- '; margin-left: 10px; font-size: 14px">
      <li style="font-family: 'Calibri', sans-serif">
        Ne pas utiliser les données auxquelles je peux accéder à des fins autres
        que celles prévues par mes attributions ;
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        Ne divulguer ces données qu’aux personnes dûment autorisées, en raison
        de leurs fonctions, à en recevoir communication, qu’il s’agisse de
        personnes privées, publiques, physiques ou morales ;
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        Ne faire aucune copie de ces données sauf à ce que cela soit nécessaire
        à l’exécution de mes fonctions ;
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        Prendre toutes les mesures conformes aux usages et à l’état de l’art
        dans le cadre de mes attributions afin d’éviter l’utilisation détournée
        ou frauduleuse de ces données ;
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        Prendre toutes précautions conformes aux usages et à l’état de l’art
        pour préserver la sécurité physique et logique de ces données ;
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        M’assurer, dans la limite de mes attributions, que seuls des moyens de
        communication sécurisés seront utilisés pour transférer ces données ;
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        En cas de cessation de mes fonctions, restituer intégralement les
        données, fichiers informatiques et tout support d’information relatif à
        ces données.
      </li>
    </ul>
    <br /><br />
    <div
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 14px;
        line-height: 20px;
      "
    >
      Cet engagement de confidentialité, en vigueur pendant toute la durée de
      mes fonctions, demeurera effectif, sans limitation de durée après la
      cessation de mes fonctions, quelle qu’en soit la cause, dès lors que cet
      engagement concerne l’utilisation et la communication de données à
      caractère personnel.
      <br /><br />

      J’ai été informé que toute violation du présent engagement m’expose à des
      sanctions disciplinaires et pénales conformément à la réglementation en
      vigueur, notamment au regard des articles 226-16 à 226-24 du code pénal.
      <br /><br />

      Fait à
      <span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.ville }}</span
      >, le
      <span
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.dateRouge }}</span
      >, en 2 exemplaires<br /><br />

      <div
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
        "
      >
        &emsp;&emsp;&emsp;Signature
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EngagementDeConfidentialite",
  props:{
    data:{
      type:Object,
      default:()=>{}
    },
    oldReference:{
      type:Object,
      default:()=>{}
    }
  },
  mounted() {
    if(!!this.oldReference && Object.keys(this.oldReference))
      this.referenceData = this.oldReference
    this.$store.dispatch('handleReferenceData',{})
  },
};
</script>

<style></style>
