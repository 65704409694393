<template>
  <v-dialog v-if="$parent.importCollaboratorsModal" :persistent="!error" width="650" v-bind="$attrs" v-on="$listeners">
    <div class="tw-px-4 tw-py-2 tw-text-black-100 tw-min">
      <div v-if="!isLoading">
        <div class="tw-flex tw-justify-end tw-mb-2">
          <button v-on:click="onClose">
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.33352 0.211923L5.00009 3.87813L8.66666 0.223033C8.73885 0.149586 8.8255 0.0919159 8.92112 0.05367C9.01674 0.0154241 9.11927 -0.00256573 9.2222 0.000838218C9.42418 0.0139081 9.6145 0.100038 9.75762 0.243146C9.90075 0.386254 9.98689 0.576556 9.99996 0.778519C10.001 0.87782 9.98178 0.97629 9.94358 1.06796C9.90539 1.15962 9.84896 1.24258 9.77774 1.31179L6.10006 5.00022L9.77774 8.68864C9.92218 8.82863 10.0022 9.02194 9.99996 9.22191C9.98689 9.42387 9.90075 9.61418 9.75762 9.75729C9.6145 9.90039 9.42418 9.98652 9.2222 9.99959C9.11927 10.003 9.01674 9.98501 8.92112 9.94676C8.8255 9.90851 8.73885 9.85085 8.66666 9.7774L5.00009 6.1223L1.34464 9.7774C1.27244 9.85085 1.1858 9.90851 1.09017 9.94676C0.99455 9.98501 0.892029 10.003 0.789095 9.99959C0.583345 9.98888 0.388856 9.90234 0.243172 9.75667C0.0974876 9.611 0.0109371 9.41653 0.000227414 9.2108C-0.000777895 9.1115 0.0184003 9.01303 0.0565986 8.92136C0.094797 8.8297 0.151219 8.74674 0.222444 8.67754L3.90012 5.00022L0.211333 1.31179C0.142114 1.24164 0.0877451 1.15827 0.0514738 1.06665C0.0152026 0.975023 -0.00222666 0.877029 0.000227414 0.778519C0.0132986 0.576556 0.0994371 0.386254 0.242559 0.243146C0.385681 0.100038 0.576002 0.0139081 0.777984 0.000838218C0.880117 -0.00401225 0.982154 0.0122271 1.07773 0.0485439C1.17331 0.0848608 1.26039 0.140476 1.33352 0.211923Z"
                :fill="referer.theme.ctaBgColor"
              />
            </svg>
          </button>
        </div>
        <div v-if="percent < 100">
          <div class="tw-text-sm tw-leading-5">
            Chacun de vos collaborateurs va faire l’objet
            d’une vérification. Cette
            vérification peut prendre quelques secondes comme quelques minutes,
            un délai qui est indépendant de notre volonté.
          </div>
          <div class="tw-mt-8">
            <div v-if="error" class="tw-text-sm tw-mb-2 tw-text-red-100">{{ error }}</div>
            <v-progress-linear
              v-model="percent"
              :color="referer.theme.ctaBgColor"
              height="30"
              class="tw-rounded-lg tw-border dynamic-border"
              background-color="white"
            ></v-progress-linear>
            <div class="tw-mt-1 dynamic-text">{{ percent }} %</div>
          </div>
          <div class="tw-flex tw-justify-center tw-my-4">
            <PrimaryButtonComponent @click="onClose"> Annuler </PrimaryButtonComponent>
          </div>
        </div>
        <div v-if="percent >= 100">
          <div class="tw-flex tw-justify-center">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 0C8.95536 0 0 8.95536 0 20C0 31.0446 8.95536 40 20 40C31.0446 40 40 31.0446 40 20C40 8.95536 31.0446 0 20 0ZM28.6384 13.4688L19.2366 26.5045C19.1052 26.6879 18.932 26.8373 18.7313 26.9404C18.5306 27.0435 18.3082 27.0973 18.0826 27.0973C17.857 27.0973 17.6346 27.0435 17.4339 26.9404C17.2332 26.8373 17.06 26.6879 16.9286 26.5045L11.3616 18.7902C11.192 18.5536 11.3616 18.2232 11.6518 18.2232H13.7455C14.2009 18.2232 14.6339 18.442 14.9018 18.817L18.0804 23.2277L25.0982 13.4955C25.3661 13.125 25.7946 12.9018 26.2545 12.9018H28.3482C28.6384 12.9018 28.808 13.2321 28.6384 13.4688Z"
                :fill="referer.theme.ctaBgColor"
              />
            </svg>
          </div>
          <div class="tw-text-sm tw-text-center tw-leading-5 tw-my-4">
            La vérification de vos collaborateurs est terminée. Vous
            pouvez maintenant accéder à votre base de données.
          </div>
          <div class="tw-flex tw-justify-center tw-mt-8">
            <PrimaryButtonComponent v-on:click="onClose"> Confirmer </PrimaryButtonComponent>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="tw-my-8">
        <div class="tw-flex tw-justify-center">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div class="tw-text-center tw-mt-4">Chargement...</div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";

export default {
  name: "CollaboratorsLoaderModal",
  components: { PrimaryButtonComponent },
  props: {
    countAll: Number,
    countFinished: Number,
    action: String,
    error: String,
    isLoading: Boolean,
    log: String,
    onClose: Function,
  },
  computed: {
    percent() {
      return (
        Math.round((this.$props.countFinished * 100) / this.$props.countAll) ||
        0
      );
    },
    referer() {
      return this.$store.state.referrer;
    },
  },
};
</script>

<style>
.v-progress-linear__determinate {
  @apply tw-rounded-lg;
}
</style>
