<template>
  <div>
    <draggable 
      class="tw-grid tw-grid-cols-3"
      v-model="myList" group="people" 
      @start="show = false" @end="show = true"
    >
      <div 
        @click="goToRoute(item.route)"
        class="tw-w-full xl:tw-col-span-1 tw-col-span-3 tw-justify-center tw-p-2 tw-items-center"
        v-for="(item, index) in myList" :key="index"
      >
        <div v-if="show" class="tw-cursor-pointer tw-h-52 tw-relative tw-items-center tw-border tw-rounded-lg tw-py-6">
          <div class="tw-flex tw-items-center tw-justify-center">
            <span class="tw-font-extrabold tw-text-lg tw-leading-5 tw-text-center tw-px-2 tw-capitalize"
              v-html="item.title"></span>
          </div>

          <div class="tw-absolute tw-top-1 tw-left-1" v-if="collaborator[item.type]">
            <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
                fill="var(--cta-bg-color)" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.2376 17.3334C11.1851 17.3298 11.1718 17.3303 11.1199 17.3213C11.0032 17.3012 10.8909 17.2569 10.7918 17.1919C10.7417 17.1591 10.698 17.1209 10.6543 17.0801L7.18741 13.6132C7.15153 13.5748 7.14148 13.566 7.10967 13.524C7.05004 13.4454 7.00402 13.3566 6.97416 13.2625C6.93832 13.1496 6.92601 13.0295 6.9381 12.9117C6.9482 12.8135 6.9752 12.7172 7.01758 12.6281C7.05147 12.5568 7.09515 12.4902 7.14702 12.4307C7.34407 12.2047 7.65211 12.0953 7.94757 12.1462C8.08382 12.1697 8.21383 12.2262 8.32399 12.3097C8.36594 12.3415 8.37474 12.3516 8.41313 12.3875L11.2672 15.2415L17.5881 8.92054L17.6313 8.88015C17.6721 8.847 17.6816 8.83755 17.7256 8.80869C17.8082 8.75456 17.8998 8.71469 17.9958 8.69134C18.1109 8.6633 18.2315 8.65918 18.3482 8.67929C18.6233 8.72674 18.8638 8.90906 18.9837 9.16119C19.0176 9.23247 19.0417 9.30836 19.0551 9.38614C19.0718 9.48343 19.0718 9.58345 19.0551 9.6807C19.0316 9.81695 18.9751 9.94696 18.8916 10.0571C18.8597 10.0991 18.8497 10.1079 18.8138 10.1463L11.88 17.0801C11.8416 17.116 11.8328 17.126 11.7909 17.1578C11.6965 17.2294 11.5875 17.2813 11.4724 17.3093C11.4142 17.3235 11.3564 17.3294 11.2968 17.3334C11.277 17.3334 11.2573 17.3334 11.2376 17.3334V17.3334Z"
                fill="white" />
            </svg>
          </div>

          <div class="tw-w-full tw-flex tw-justify-center tw-items-center tw-space-x-2 tw-absolute tw-bottom-8">
            <div v-html="item.svg">
            </div>
            <v-progress-circular :key="index" :indeterminate=false :rotate="-90" :size="80" :width="3"
              :value="item.percentage * 100 / 60" :color="referer.theme.ctaBgColor" v-if="!collaborator[item.type]">
              <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
                <div class="color-primary tw-text-2xl tw-font-bold">{{ item.percentage }}</div>
                <div class="tw-text-xs" :style="{ color: referer.theme.ctaBgColor }">min</div>
              </div>
            </v-progress-circular>
            <div class="tw-flex tw-flex-col tw-justify-center tw-items-center" v-else>
              <v-chip :color="referer.theme.ctaBgColor" class="tw-text-white" style="padding:14px !important"
                @click.stop="showPopup(item.type)">
                Détails
              </v-chip>
            </div>
          </div>
        </div>
        <div v-else
          class=" tw-h-52 tw-flex tw-justify-center tw-items-center tw-border-dashed tw-border-2 tw-border-black tw-rounded-lg tw-p-6">
          <span class="tw-font-bold tw-text-3xl">+</span>
        </div>
      </div>
    </draggable>

    <div v-if="showModal && modalName === 'ecosystem'" class="content">
      <ModalDashboardEcosystem :show="showModal" type="ecosystem" />
    </div>
    <div v-if="showModal && modalName === 'governance'" class="content">
      <ModalDashboardGouvernance :show="showModal" type="governance" />
    </div>
    <div v-if="showModal && modalName === 'contractAmendments'" class="content">
      <ModalDashboardRGPD :show="showModal" type="rgpd-process" />
    </div>
    <div v-if="showModal && modalName === 'processingRegister'" class="content">
      <ModalDashboardProcessingRegister :show="showModal" type="processingRegister" />
    </div>
    <div v-if="showModal && modalName === 'subContractorEvaluation'" class="content">
      <ModalDashboardSubContractor :show="showModal" type="subContractorEvaluation" />
    </div>
    <div v-if="showModal && modalName === 'contributors'" class="content">
      <ModalDashboardCollaborator :show="showModal" type="contributors" />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ModalDashboardEcosystem from '@/features/company/components/Dashboard/modalDashboardEcosystem'
import ModalDashboardGouvernance from '@/features/company/components/Dashboard/modalDashboardGouvernance'
import DashboardItem from "../Mocks/data"
import ModalDashboardRGPD from "@/features/company/components/Dashboard/ModalDashboardRGPD";
import ModalDashboardProcessingRegister from "@/features/company/components/Dashboard/ModalDashboardProcessingRegister";
import ModalDashboardSubContractor from "@/features/company/components/Dashboard/ModalDashboardSubContractor";
import ModalDashboardCollaborator from "@/features/company/components/Dashboard/ModalDashboardCollaborator";
// import BarChartSubContractor from "@/features/company/components/Dashboard/BarChartSubContractor";

export default {
  name: "SubContractorBolck",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    myList: {
      get() {
        return this.$store.getters.getDashboardOrder || DashboardItem
      },
      set(value) {
        this.$store.commit('SET_DASHBOARD_ORDER', value);
      }
    }
  },
  components: {
    ModalDashboardCollaborator,
    ModalDashboardSubContractor,
    ModalDashboardProcessingRegister,
    ModalDashboardRGPD,
    draggable,
    ModalDashboardEcosystem,
    ModalDashboardGouvernance
  },
  props: {
    collaborator: { type: Object, default: function () { return {} }, },
  },
  mounted() {

  },
  data() {
    return {
      showModal: false,
      modalName: null,
      show: true,
      data: []

    }
  },
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route })
    },
    color(item) {
      if (item.percentage < 30)
        return "red"
      else if (item.percentage < 75)
        return "orange"
      else
        return "green"
    },
    showPopup(item) {
      this.showModal = true
      this.modalName = item
    },
  }
}
</script>

<style scoped>
.second-part-subconstractor {
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  border-radius: 16px;
  margin: 20px 0;
}
</style>
